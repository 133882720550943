import { hankanaToZenkana } from '@/utils/convert_string'
import {
  VALID_SHOW_ID_EMPTY_REGEX,
  VALID_NUMERIC_STRING_REGEX,
  VALID_KATAKANA_EMPTY_REGEX,
  VALID_SURROGATE_PAIR_REGEX,
  VALID_DATE_EMPTY_REGEX,
  VALID_DATE_SLASH_ZERO_REGEX,
  VALID_DATE_SLASH_NO_ZERO_REGEX,
  VALID_YEAR_MONTH_REGEX,
  VALID_YEAR_MONTH_SLASH_REGEX,
  VALID_YEAR_REGEX,
  VALID_ANICOM_C_ID_REGEX,
  VALID_FULL_NAME_REGEX,
  VALID_FULL_NAME_KANA_REGEX,
  VALID_POSTALCODE_EMPTY_REGEX,
  VALID_POSTALCODE_HYPHEN_REGEX,
  VALID_TEL_EMPTY_REGEX,
  VALID_TEL_HYPHEN_REGEX,
  VALID_EMAIL_EMPTY_REGEX,
  INSURANCES_EMPTY,
  IPET_PLANS,
  DOCOMO_PLANS,
  STRING_RATES_BY_PLAN
} from '@/utils/define'
import { convertVariousDateFormatToYYYYMMDD } from '@/utils/moment'
import _ from 'lodash'
import moment from 'moment'
import { parse } from 'csv-parse/lib/sync'

export default {
  computed: {
    mixinStorePatientMcCodeKeyObj() {
      return this.selectCsvType === 'petorelu-plus'
        ? _.keyBy(
            this.$store.getters['patients/getData'].filter(
              v => v.mcCode.length > 0
            ),
            'mcCode'
          )
        : null
    },
    mixinStoreOwnerEmailKeyObj() {
      return this.selectCsvType === 'petorelu-plus'
        ? _.keyBy(
            this.$store.getters['owners/getData'].filter(
              v => v.email.length > 0
            ),
            'email'
          )
        : null
    },
    mixinPatientSexNamesZero() {
      return this.selectCsvType === 'petorelu-plus'
        ? this.$store.getters['master/selectPatientSexesZero'].map(v => v.name)
        : null
    },
    mixinPrefectureNamesZero() {
      return this.selectCsvType === 'petorelu-plus'
        ? this.$store.getters['master/selectPrefecturesZero'].map(v => v.name)
        : null
    }
  },

  methods: {
    mixinMakeCsvArrayPetoreluPlusFormat(csvStr) {
      try {
        const rows = parse(csvStr, { trim: true })
        const csvArray = rows
          .map((row, index) => {
            if (row.length === 0) return
            const data = this.mixinReplaceElementsPetoreluPlusFormat(row)
            if (
              (data[1] === 'true' || data[1] === 'false') &&
              data.length === 32
            ) {
              return {
                rowNo: parseInt(data[0]),
                isValid: data[1] === 'true' ? true : false,
                patientShowId: data[2],
                mcCode: data[3],
                patientName: data[4],
                patientNameKana: data[5],
                patientSexName: data[6],
                hairColor: data[7],
                speciesName: data[8],
                breed: data[9],
                patientBirthday: data[10],
                insurance: data[11],
                anicomCId: data[12],
                policyNo: data[13],
                planNm: data[14],
                claimRate: data[15],
                startDate: data[16],
                endDate: data[17],
                firstContractDate: data[18],
                patientNote: data[19],
                ownerShowId: data[20],
                ownerFullName: data[21],
                ownerFullNameKana: data[22],
                ownerSexName: data[23],
                ownerBirthday: data[24],
                postalCode: data[25],
                prefectureName: data[26],
                address: data[27],
                building: data[28],
                tel: data[29],
                email: data[30],
                ownerNote: data[31]
              }
            } else if (data.length === 30) {
              return {
                rowNo: index,
                isValid: true,
                patientShowId: data[0],
                mcCode: data[1],
                patientName: data[2],
                patientNameKana: data[3],
                patientSexName: data[4],
                hairColor: data[5],
                speciesName: data[6],
                breed: data[7],
                patientBirthday: data[8],
                insurance: data[9],
                anicomCId: data[10],
                policyNo: data[11],
                planNm: data[12],
                claimRate: data[13],
                startDate: data[14],
                endDate: data[15],
                firstContractDate: data[16],
                patientNote: data[17],
                ownerShowId: data[18],
                ownerFullName: data[19],
                ownerFullNameKana: data[20],
                ownerSexName: data[21],
                ownerBirthday: data[22],
                postalCode: data[23],
                prefectureName: data[24],
                address: data[25],
                building: data[26],
                tel: data[27],
                email: data[28],
                ownerNote: data[29]
              }
            } else {
              throw new Error('invalid row length')
            }
          })
          .filter(v => v !== undefined)
        this.csvArray = csvArray
        const csvArrayValidRowOnly = csvArray.filter(v => v.isValid === true)
        this.ownerShowIdGroupObj = _.cloneDeep(
          _.groupBy(csvArrayValidRowOnly, 'ownerShowId')
        )
        this.patientShowIdGroupObj = _.cloneDeep(
          _.groupBy(csvArrayValidRowOnly, 'patientShowId')
        )
        this.mcCodeGroupObj = _.cloneDeep(
          _.groupBy(csvArrayValidRowOnly, 'mcCode')
        )
        this.emailGroupObj = _.cloneDeep(
          _.groupBy(csvArrayValidRowOnly, 'email')
        )
      } catch (err) {
        this.openErrorAnnounce('適切なデータとして読み込めませんでした')
      }
    },
    mixinReplaceElementsPetoreluPlusFormat(data) {
      //文字化けした値があるとreplace処理でエラーになるので注意
      if ((data[1] === 'true' || data[1] === 'false') && data.length === 32) {
        data[21] = this.mixinReplaceOwnerFullName(data[21])
        data[22] = this.mixinReplaceOwnerFullNameKana(data[22])
      } else {
        data[19] = this.mixinReplaceOwnerFullName(data[19])
        data[20] = this.mixinReplaceOwnerFullNameKana(data[20])
      }
      return data
    },
    mixinReplaceOwnerFullName(ownerFullName) {
      return ownerFullName.replace(/[\x20\u3000]+/, '　')
    },
    mixinReplaceOwnerFullNameKana(ownerFullNameKana) {
      return hankanaToZenkana(ownerFullNameKana)
        .replace(/[\x20\u3000]+/, '　')
        .replace(/[・]/, '')
    },
    mixinIsInvalidDate(date) {
      return (
        !VALID_DATE_EMPTY_REGEX.test(date) &&
        !VALID_DATE_SLASH_ZERO_REGEX.test(date) &&
        !VALID_DATE_SLASH_NO_ZERO_REGEX.test(date)
      )
    },
    mixinIsAfterCurrentYear(date) {
      return date.slice(0, 4) > moment().format('YYYY')
    },
    mixinIsPatientBirthdayInvalid(hasInsurance, patientBirthday) {
      if (hasInsurance) {
        return (
          patientBirthday.length === 0 ||
          this.mixinIsInvalidDate(patientBirthday) ||
          this.mixinIsAfterCurrentYear(patientBirthday)
        )
      } else {
        const isInvalidYearMonth =
          !VALID_YEAR_MONTH_REGEX.test(patientBirthday) &&
          !VALID_YEAR_MONTH_SLASH_REGEX.test(patientBirthday)
        const isInvalidYear = !VALID_YEAR_REGEX.test(patientBirthday)
        return (
          (this.mixinIsInvalidDate(patientBirthday) &&
            isInvalidYearMonth &&
            isInvalidYear) ||
          this.mixinIsAfterCurrentYear(patientBirthday)
        )
      }
    },
    mixinValidateIpetColumns(hasIpetOrDocomoInsurance, row) {
      if (hasIpetOrDocomoInsurance) {
        const {
          insurance,
          policyNo,
          planNm,
          claimRate,
          startDate,
          endDate,
          firstContractDate
        } = row
        const isInvalidPolicyNo =
          policyNo.length === 0 ||
          !VALID_NUMERIC_STRING_REGEX(10).test(policyNo)

        const isInvalidPlanNm =
          planNm.length === 0 ||
          (insurance === 'ipet' && !IPET_PLANS.includes(planNm)) ||
          (insurance === 'docomo' && planNm !== DOCOMO_PLANS[0])

        const rates = STRING_RATES_BY_PLAN[planNm]
        const isInvalidClaimRate =
          claimRate.length === 0 || !rates || !rates.includes(claimRate)

        const isInvalidStartDate =
          startDate.length === 0 || this.mixinIsInvalidDate(startDate)

        const isInvalidEndDate =
          endDate.length === 0 || this.mixinIsInvalidDate(endDate)

        const isStartDateAfterEndDate =
          isInvalidStartDate || isInvalidEndDate
            ? false //どちらかが不正な値の場合は既にバリエーションエラーになっていて、また正確な比較もできないためfalseをセットする
            : convertVariousDateFormatToYYYYMMDD(startDate) >
              convertVariousDateFormatToYYYYMMDD(endDate)

        const isInvalidFirstContractDate = this.mixinIsInvalidDate(
          firstContractDate
        )
        return {
          isInvalidPolicyNo,
          isInvalidPlanNm,
          isInvalidClaimRate,
          isInvalidStartDate,
          isInvalidEndDate,
          isStartDateAfterEndDate,
          isInvalidFirstContractDate
        }
      } else {
        return {
          isInvalidPolicyNo: false,
          isInvalidPlanNm: false,
          isInvalidClaimRate: false,
          isInvalidStartDate: false,
          isInvalidEndDate: false,
          isStartDateAfterEndDate: false,
          isInvalidFirstContractDate: false
        }
      }
    },
    mixinMakeInvalidPagesPetoreluPlusFormat() {
      const invalidRows = this.csvArray
        .map((row, i) => {
          if (!row.isValid) return
          const sameShowIdOwnerInDatabase = this.storeOwnerShowIdKeyObj[
            row.ownerShowId
          ]
          const sameShowIdPatientInDatabase = this.storePatientShowIdKeyObj[
            row.patientShowId
          ]

          //patient
          const isInvalidPatientShowId =
            row.patientShowId.length === 0 ||
            !VALID_SHOW_ID_EMPTY_REGEX.test(row.patientShowId) ||
            this.patientShowIdGroupObj[row.patientShowId].length > 1 ||
            row.patientShowId.length > 20 ||
            //↓既存優先で、登録済み患者で、新規飼主の場合。飼主だけの作成はできない仕様にしたのでバリエーションエラーにする
            (this.priority === 1 &&
              sameShowIdPatientInDatabase &&
              !sameShowIdOwnerInDatabase)

          const sameMcCodePatientInDatabase = this
            .mixinStorePatientMcCodeKeyObj[row.mcCode]
          const isUsedInDatabase =
            (this.priority === 2 && //上書き
              sameMcCodePatientInDatabase &&
              sameMcCodePatientInDatabase.showId !== row.patientShowId) ||
            (this.priority === 1 && //既存優先
            !this.storePatientShowIdKeyObj[row.patientShowId] && //新規患者の場合
              sameMcCodePatientInDatabase &&
              sameMcCodePatientInDatabase.showId !== row.patientShowId)
          const isInvalidMcCode =
            !VALID_NUMERIC_STRING_REGEX(15).test(row.mcCode) ||
            isUsedInDatabase ||
            (row.mcCode.length > 0 &&
              this.mcCodeGroupObj[row.mcCode].length > 1)

          const isInvalidPatientName =
            !row.patientName ||
            !row.patientName.match(/\S/g) ||
            VALID_SURROGATE_PAIR_REGEX.test(row.patientName) ||
            row.patientName.length > 30

          const isInvalidPatientNameKana =
            VALID_SURROGATE_PAIR_REGEX.test(row.patientNameKana) ||
            row.patientNameKana.length > 30 ||
            !VALID_KATAKANA_EMPTY_REGEX.test(row.patientNameKana)

          const isInvalidPatientSexName = !this.mixinPatientSexNamesZero.includes(
            row.patientSexName
          )

          const isInvalidHairColor =
            VALID_SURROGATE_PAIR_REGEX.test(row.hairColor) ||
            row.hairColor.length > 50

          const isInvalidSpeciesName = !this.speciesNames.includes(
            row.speciesName
          )

          const isInvalidBreed =
            VALID_SURROGATE_PAIR_REGEX.test(row.breed) || row.breed.length > 50

          const hasAnicomInsurance = row.insurance === 'anicom'
          const hasIpetOrDocomoInsurance =
            row.insurance === 'ipet' || row.insurance === 'docomo'
          const hasInsurance = hasAnicomInsurance || hasIpetOrDocomoInsurance

          const isInvalidPatientBirthday = this.mixinIsPatientBirthdayInvalid(
            hasInsurance,
            row.patientBirthday
          )

          const isInvalidInsurance = !INSURANCES_EMPTY.includes(row.insurance)

          const isInvalidAnicomCId =
            hasAnicomInsurance &&
            (row.anicomCId.length === 0 ||
              !VALID_ANICOM_C_ID_REGEX.test(row.anicomCId))

          const {
            isInvalidPolicyNo,
            isInvalidPlanNm,
            isInvalidClaimRate,
            isInvalidStartDate,
            isInvalidEndDate,
            isStartDateAfterEndDate,
            isInvalidFirstContractDate
          } = this.mixinValidateIpetColumns(hasIpetOrDocomoInsurance, row)

          const isInvalidPatientNote = VALID_SURROGATE_PAIR_REGEX.test(
            row.patientNote
          )

          //owner
          const isInvalidOwnerShowId =
            row.ownerShowId.length === 0 ||
            !VALID_SHOW_ID_EMPTY_REGEX.test(row.ownerShowId) ||
            this.mixinCheckIsSameOwnerShowIdDifferentOwnersPetoreluPlus(row) ||
            row.ownerShowId.length > 20 ||
            //↓上書きで、登録済み患者で、登録済み患者に紐づいた飼主のshowIdと入力した飼主showIdが一致しない場合
            (this.priority === 2 &&
              sameShowIdPatientInDatabase &&
              sameShowIdPatientInDatabase.ownerShowId !== row.ownerShowId)

          const isInvalidOwnerFullName =
            row.ownerFullName.length === 0 ||
            VALID_SURROGATE_PAIR_REGEX.test(row.ownerFullName) ||
            !VALID_FULL_NAME_REGEX.test(row.ownerFullName)

          const isInvalidOwnerFullNameKana =
            row.ownerFullNameKana.length === 0 ||
            !VALID_FULL_NAME_KANA_REGEX.test(row.ownerFullNameKana)

          const ownerSexNamesZero = ['', '男性', '女性', 'その他']
          const isInvalidOwnerSexName = !ownerSexNamesZero.includes(
            row.ownerSexName
          )

          const isInvalidOwnerBirthday =
            this.mixinIsInvalidDate(row.ownerBirthday) ||
            this.mixinIsAfterCurrentYear(row.ownerBirthday)

          const isInvalidPostalCode =
            !VALID_POSTALCODE_EMPTY_REGEX.test(row.postalCode) &&
            !VALID_POSTALCODE_HYPHEN_REGEX.test(row.postalCode)

          const isInvalidPrefectureName = !this.mixinPrefectureNamesZero.includes(
            row.prefectureName
          )

          const isInvalidAddress =
            VALID_SURROGATE_PAIR_REGEX.test(row.address) ||
            row.address.length > 50

          const isInvalidBuilding =
            VALID_SURROGATE_PAIR_REGEX.test(row.building) ||
            row.building.length > 50

          const isInvalidTel =
            !VALID_TEL_EMPTY_REGEX.test(row.tel) &&
            !VALID_TEL_HYPHEN_REGEX.test(row.tel)

          const sameEmailOwnerInDatabase = this.mixinStoreOwnerEmailKeyObj[
            row.email
          ]
          const isUsedEmailInDatabase =
            (this.priority === 2 && //上書き
              sameEmailOwnerInDatabase &&
              sameEmailOwnerInDatabase.showId !== row.ownerShowId) ||
            (this.priority === 1 && //既存優先
            !this.storeOwnerShowIdKeyObj[row.ownerShowId] && //新規飼主の場合
              sameEmailOwnerInDatabase &&
              sameEmailOwnerInDatabase.showId !== row.ownerShowId)
          const sameEmailOwners = this.emailGroupObj[row.email]
          const localPart = row.email.slice(0, row.email.lastIndexOf('@'))
          const isInvalidEmail =
            !(
              VALID_EMAIL_EMPTY_REGEX.test(row.email) &&
              localPart.length <= 64 &&
              row.email.length <= 254
            ) ||
            isUsedEmailInDatabase ||
            (row.email.length > 0 &&
              sameEmailOwners &&
              sameEmailOwners.some(
                owner => owner.ownerShowId !== row.ownerShowId
              ))

          const isInvalidOwnerNote = VALID_SURROGATE_PAIR_REGEX.test(
            row.ownerNote
          )

          if (
            isInvalidPatientShowId ||
            isInvalidMcCode ||
            isInvalidPatientName ||
            isInvalidPatientNameKana ||
            isInvalidPatientSexName ||
            isInvalidHairColor ||
            isInvalidSpeciesName ||
            isInvalidBreed ||
            isInvalidPatientBirthday ||
            isInvalidInsurance ||
            isInvalidAnicomCId ||
            isInvalidPolicyNo ||
            isInvalidPlanNm ||
            isInvalidClaimRate ||
            isInvalidStartDate ||
            isInvalidEndDate ||
            isStartDateAfterEndDate ||
            isInvalidFirstContractDate ||
            isInvalidPatientNote ||
            isInvalidOwnerShowId ||
            isInvalidOwnerFullName ||
            isInvalidOwnerFullNameKana ||
            isInvalidOwnerSexName ||
            isInvalidOwnerBirthday ||
            isInvalidPostalCode ||
            isInvalidPrefectureName ||
            isInvalidAddress ||
            isInvalidBuilding ||
            isInvalidTel ||
            isInvalidEmail ||
            isInvalidOwnerNote
          ) {
            return i + 1
          }
        })
        .filter(v => v !== undefined)
      const invalidPages = invalidRows.map(v => Math.ceil(v / this.rowNum))
      this.invalidPages = Array.from(new Set(invalidPages))
    },
    mixinCheckIsSameOwnerShowIdDifferentOwnersPetoreluPlus(row) {
      const sameOwnerShowIdData = this.ownerShowIdGroupObj[row.ownerShowId]
      return (
        row.ownerShowId.length > 0 &&
        sameOwnerShowIdData &&
        sameOwnerShowIdData.some(v => {
          return (
            v.ownerFullName !== row.ownerFullName ||
            v.ownerFullNameKana !== row.ownerFullNameKana ||
            v.ownerSexName !== row.ownerSexName ||
            v.ownerBirthday !== row.ownerBirthday ||
            v.postalCode !== row.postalCode ||
            v.prefectureName !== row.prefectureName ||
            v.address !== row.address ||
            v.building !== row.building ||
            v.tel !== row.tel ||
            v.email !== row.email ||
            v.ownerNote !== row.ownerNote
          )
        })
      )
    },
    mixinInputCsvPetoreluPlus(obj) {
      this.$set(this.dispCsvArray[obj.index], obj.key, obj.value)
      this.$set(
        this.csvArray[obj.index + this.currentPageStartIndex],
        obj.key,
        obj.value
      )
      if (obj.key === 'isValid') {
        this.mixinReplaceGroupObjIsValid(
          obj,
          'ownerShowId',
          this.ownerShowIdGroupObj
        )
        this.mixinReplaceGroupObjIsValid(
          obj,
          'patientShowId',
          this.patientShowIdGroupObj
        )
        this.mixinReplaceGroupObjIsValid(obj, 'mcCode', this.mcCodeGroupObj)
        this.mixinReplaceGroupObjIsValid(obj, 'email', this.emailGroupObj)
      } else if (obj.key === 'ownerShowId') {
        const oldOwnerShowId = obj.before.ownerShowId
        const newOwnerShowId = obj.value
        const updatedPatientDatum = this.dispCsvArray[obj.index]
        this.mixinReplaceGroupObjNotIsValid(
          oldOwnerShowId,
          newOwnerShowId,
          updatedPatientDatum,
          this.ownerShowIdGroupObj
        )
        //変更したownerShowIdの値をthis.emailGroupObj内のデータにも反映する
        //（違う飼主で同じメールアドレスが使用されていないかのチェックで使用するため）
        const key = updatedPatientDatum.email
        this.mixinReplaceGroupObjAnotherKey(
          key,
          updatedPatientDatum,
          this.emailGroupObj
        )
      } else if (obj.key === 'patientShowId') {
        const oldPatientShowId = obj.before.patientShowId
        const newPatientShowId = obj.value
        const updatedPatientDatum = this.dispCsvArray[obj.index]
        this.mixinReplaceGroupObjNotIsValid(
          oldPatientShowId,
          newPatientShowId,
          updatedPatientDatum,
          this.patientShowIdGroupObj
        )
      } else if (obj.key === 'mcCode') {
        const oldMcCode = obj.before.mcCode
        const newMcCode = obj.value
        const updatedPatientDatum = this.dispCsvArray[obj.index]
        this.mixinReplaceGroupObjNotIsValid(
          oldMcCode,
          newMcCode,
          updatedPatientDatum,
          this.mcCodeGroupObj
        )
      } else if (obj.key === 'email') {
        const oldEmail = obj.before.email
        const newEmail = obj.value
        const updatedPatientDatum = this.dispCsvArray[obj.index]
        this.mixinReplaceGroupObjNotIsValid(
          oldEmail,
          newEmail,
          updatedPatientDatum,
          this.emailGroupObj
        )
      }
      if (
        obj.key === 'ownerFullName' ||
        obj.key === 'ownerFullNameKana' ||
        obj.key === 'ownerSexName' ||
        obj.key === 'ownerBirthday' ||
        obj.key === 'postalCode' ||
        obj.key === 'prefectureName' ||
        obj.key === 'address' ||
        obj.key === 'building' ||
        obj.key === 'tel' ||
        obj.key === 'email' ||
        obj.key === 'ownerNote'
      ) {
        const updatedPatientDatum = this.dispCsvArray[obj.index]
        const key = updatedPatientDatum.ownerShowId
        this.mixinReplaceGroupObjAnotherKey(
          key,
          updatedPatientDatum,
          this.ownerShowIdGroupObj
        )
      }
    },
    mixinReplaceGroupObjIsValid(obj, columnName, thisGroupObj) {
      const key = obj.before[columnName]
      if (obj.value) {
        thisGroupObj[key] === undefined
          ? this.$set(thisGroupObj, key, [obj.before])
          : thisGroupObj[key].push(obj.before)
      } else {
        if (thisGroupObj[key].length === 1) {
          this.$delete(thisGroupObj, key)
        } else {
          const newGroup = thisGroupObj[key].filter(
            v => v.rowNo !== obj.before.rowNo
          )
          thisGroupObj[key].splice(0, thisGroupObj[key].length)
          thisGroupObj[key].push(...newGroup)
        }
      }
    },
    mixinReplaceGroupObjNotIsValid(
      oldKey,
      newKey,
      updatedPatientDatum,
      thisGroupObj
    ) {
      if (thisGroupObj[oldKey].length === 1) {
        this.$delete(thisGroupObj, oldKey)
      } else {
        const newGroup = thisGroupObj[oldKey].filter(
          v => v.rowNo !== updatedPatientDatum.rowNo
        )
        thisGroupObj[oldKey].splice(0, thisGroupObj[oldKey].length)
        thisGroupObj[oldKey].push(...newGroup)
      }
      thisGroupObj[newKey] === undefined
        ? this.$set(thisGroupObj, newKey, [updatedPatientDatum])
        : thisGroupObj[newKey].push(updatedPatientDatum)
    },
    mixinReplaceGroupObjAnotherKey(key, updatedPatientDatum, thisGroupObj) {
      const index = thisGroupObj[key].findIndex(
        v => v.rowNo === updatedPatientDatum.rowNo
      )
      this.$set(thisGroupObj[key], index, updatedPatientDatum)
    }
  }
}

<template>
  <div class="data-import-petorelu-plus-form">
    <div class="form">
      <label v-if="index === 0">有効/無効</label>
      <select :value="item.isValid" @change="change">
        <option :value="true">有効</option>
        <option :value="false">無効</option>
      </select>
    </div>
    <div class="form">
      <label v-if="index === 0"
        >患者ID <label class="required">必須</label></label
      >
      <input
        :class="{ error: errorPatientShowId }"
        :value="item.patientShowId"
        :disabled="!item.isValid"
        maxlength="20"
        @input="input($event, 'patientShowId')"
        data-test="input-patient-show-id"
      />
      <div class="patient-show-id-info">{{ patientShowIdInfo }}</div>
      <div
        class="error-message patient-show-id"
        data-test="error-message-patient-show-id"
      >
        {{ isInvalidPatientShowId(item.patientShowId) || '' }}
      </div>
    </div>

    <div class="form mc-code">
      <label v-if="index === 0">MC識別番号</label>
      <input
        class="mc-code"
        :class="{ error: errorMcCode }"
        :value="item.mcCode"
        :disabled="!item.isValid"
        maxlength="15"
        @input="input($event, 'mcCode')"
        data-test="input-mc-code"
      />
      <div class="error-message mc-code" data-test="error-message-mc-code">
        {{ isInvalidMcCode(item.mcCode) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0"
        >患者名 <label class="required">必須</label></label
      >
      <input
        :class="{ error: errorPatientName }"
        :value="item.patientName"
        :disabled="!item.isValid"
        maxlength="30"
        @input="input($event, 'patientName')"
        data-test="input-patient-name"
      />
      <div
        class="error-message patient-name"
        data-test="error-message-patient-name"
      >
        {{ isInvalidPatientName(item.patientName) || '' }}
      </div>
    </div>

    <div class="form patient-name-kana">
      <label v-if="index === 0">患者名（全角カナ）</label>
      <input
        class="patient-name-kana"
        :class="{ error: errorPatientNameKana }"
        :value="item.patientNameKana"
        :disabled="!item.isValid"
        maxlength="30"
        @input="input($event, 'patientNameKana')"
        data-test="input-patient-name-kana"
      />
      <div
        class="error-message patient-name-kana"
        data-test="error-message-patient-name-kana"
      >
        {{ isInvalidPatientNameKana(item.patientNameKana) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0">患者の性別</label>
      <input
        :class="{ error: errorPatientSexName }"
        :value="item.patientSexName"
        :disabled="!item.isValid"
        maxlength="1"
        @input="input($event, 'patientSexName')"
        data-test="input-patient-sex-name"
      />
      <div
        class="error-message patient-sex-name"
        data-test="error-message-patient-sex-name"
      >
        {{ isInvalidPatientSexName(item.patientSexName) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0">毛色</label>
      <input
        :class="{ error: errorHairColor }"
        :value="item.hairColor"
        :disabled="!item.isValid"
        maxlength="50"
        @input="input($event, 'hairColor')"
        data-test="input-hair-color"
      />
      <div
        class="error-message hair-color"
        data-test="error-message-hair-color"
      >
        {{ isInvalidHairColor(item.hairColor) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0"
        >種別 <label class="required">必須</label></label
      >
      <input
        :class="{ error: errorSpeciesName }"
        :value="item.speciesName"
        :disabled="!item.isValid"
        @input="input($event, 'speciesName')"
        data-test="input-species-name"
      />
      <div
        class="error-message species-name"
        data-test="error-message-species-name"
      >
        {{ isInvalidSpeciesName(item.speciesName) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">品種</label>
      <input
        :class="{ error: errorBreed }"
        :value="item.breed"
        :disabled="!item.isValid"
        maxlength="50"
        @input="input($event, 'breed')"
        data-test="input-breed"
      />
      <div class="error-message breed" data-test="error-message-breed">
        {{ isInvalidBreed(item.breed) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0">患者の生年月日</label>
      <input
        :class="{ error: errorPatientBirthday }"
        :value="item.patientBirthday"
        :disabled="!item.isValid"
        maxlength="10"
        @input="input($event, 'patientBirthday')"
        data-test="input-patient-birthday"
      />
      <div
        class="error-message patient-birthday"
        data-test="error-message-patient-birthday"
      >
        {{ isInvalidPatientBirthday(item.patientBirthday) || '' }}
      </div>
    </div>

    <div class="form insurance">
      <label v-if="index === 0">加入している保険</label>
      <input
        class="insurance"
        :class="{ error: errorInsurance }"
        :value="item.insurance"
        :disabled="!item.isValid"
        maxlength="10"
        @input="input($event, 'insurance')"
        data-test="input-insurance"
      />
      <div class="error-message insurance" data-test="error-message-insurance">
        {{ isInvalidInsurance(item.insurance) || '' }}
      </div>
    </div>

    <div class="form anicom-c-id">
      <label v-if="index === 0">アニコム/証券番号</label>
      <input
        class="anicom-c-id"
        :class="{ error: errorAnicomCId }"
        :value="item.anicomCId"
        :disabled="!item.isValid || !hasAnicomInsurance"
        maxlength="10"
        @input="input($event, 'anicomCId')"
        data-test="input-anicom-c-id"
      />
      <div
        class="error-message anicom-c-id"
        data-test="error-message-anicom-c-id"
      >
        {{ isInvalidAnicomCId(item.anicomCId) || '' }}
      </div>
    </div>

    <div class="form policy-no">
      <label v-if="index === 0">アイペット/証券番号</label>
      <input
        class="policy-no"
        :class="{ error: errorPolicyNo }"
        :value="item.policyNo"
        :disabled="!item.isValid || !hasIpetOrDocomoInsurance"
        maxlength="10"
        @input="input($event, 'policyNo')"
        data-test="input-policy-no"
      />
      <div class="error-message policy-no" data-test="error-message-policy-no">
        {{ isInvalidPolicyNo(item.policyNo) || '' }}
      </div>
    </div>

    <div class="form plan-nm">
      <label v-if="index === 0">アイペット/商品名</label>
      <input
        class="plan-nm"
        :class="{ error: errorPlanNm }"
        :value="item.planNm"
        :disabled="!item.isValid || !hasIpetOrDocomoInsurance"
        maxlength="15"
        @input="input($event, 'planNm')"
        data-test="input-plan-nm"
      />
      <div class="error-message plan-nm" data-test="error-message-plan-nm">
        {{ isInvalidPlanNm(item.planNm) || '' }}
      </div>
    </div>

    <div class="form claim-rate">
      <label v-if="index === 0">アイペット/補償割合（%）</label>
      <input
        class="claim-rate"
        :class="{ error: errorClaimRate }"
        :value="item.claimRate"
        :disabled="!item.isValid || !hasIpetOrDocomoInsurance"
        maxlength="3"
        @input="input($event, 'claimRate')"
        data-test="input-claim-rate"
      />
      <div
        class="error-message claim-rate"
        data-test="error-message-claim-rate"
      >
        {{ isInvalidClaimRate(item.claimRate) || '' }}
      </div>
    </div>

    <div class="form start-date">
      <label v-if="index === 0">アイペット/保険期間の開始日</label>
      <input
        class="start-date"
        :class="{ error: errorStartDate }"
        :value="item.startDate"
        :disabled="!item.isValid || !hasIpetOrDocomoInsurance"
        maxlength="10"
        @input="input($event, 'startDate')"
        data-test="input-start-date"
      />
      <div
        class="error-message start-date"
        data-test="error-message-start-date"
      >
        {{ isInvalidStartDate(item.startDate) || '' }}
      </div>
    </div>

    <div class="form end-date">
      <label v-if="index === 0">アイペット/保険期間の終了日</label>
      <input
        class="end-date"
        :class="{ error: errorEndDate }"
        :value="item.endDate"
        :disabled="!item.isValid || !hasIpetOrDocomoInsurance"
        maxlength="10"
        @input="input($event, 'endDate')"
        data-test="input-end-date"
      />
      <div class="error-message end-date" data-test="error-message-end-date">
        {{ isInvalidEndDate(item.endDate) || '' }}
      </div>
    </div>

    <div class="form first-contract-date">
      <label v-if="index === 0">アイペット/初年度契約日</label>
      <input
        class="first-contract-date"
        :class="{ error: errorFirstContractDate }"
        :value="item.firstContractDate"
        :disabled="!item.isValid || !hasIpetOrDocomoInsurance"
        maxlength="10"
        @input="input($event, 'firstContractDate')"
        data-test="input-first-contract-date"
      />
      <div
        class="error-message first-contract-date"
        data-test="error-message-first-contract-date"
      >
        {{ isInvalidFirstContractDate(item.firstContractDate) || '' }}
      </div>
    </div>

    <div class="form patient-note">
      <label v-if="index === 0">患者の備考</label>
      <textarea
        class="patient-note"
        :class="{ error: errorPatientNote }"
        :value="item.patientNote"
        :disabled="!item.isValid"
        @input="input($event, 'patientNote')"
        data-test="input-patient-note"
      />
      <div
        class="error-message patient-note"
        data-test="error-message-patient-note"
      >
        {{ isInvalidPatientNote(item.patientNote) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0"
        >飼主ID <label class="required">必須</label></label
      >
      <input
        :class="{ error: errorOwnerShowId }"
        :value="item.ownerShowId"
        :disabled="!item.isValid"
        maxlength="20"
        @input="input($event, 'ownerShowId')"
        data-test="input-owner-show-id"
      />
      <div class="owner-show-id-info">{{ ownerShowIdInfo }}</div>
      <div
        class="error-message owner-show-id"
        data-test="error-message-owner-show-id"
      >
        {{ isInvalidOwnerShowId(item.ownerShowId) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0"
        >飼主名 <label class="required">必須</label></label
      >
      <input
        :class="{ error: errorOwnerFullName }"
        :value="item.ownerFullName"
        :disabled="!item.isValid"
        maxlength="31"
        @input="input($event, 'ownerFullName')"
        data-test="input-owner-full-name"
      />
      <div
        class="error-message owner-full-name"
        data-test="error-message-owner-full-name"
      >
        {{ isInvalidOwnerFullName(item.ownerFullName) || '' }}
      </div>
    </div>
    <div class="form owner-full-name-kana">
      <label v-if="index === 0"
        >飼主名（全角カナ）<label class="required">必須</label></label
      >
      <input
        class="owner-full-name-kana"
        :class="{ error: errorOwnerFullNameKana }"
        :value="item.ownerFullNameKana"
        :disabled="!item.isValid"
        maxlength="31"
        @input="input($event, 'ownerFullNameKana')"
        data-test="input-owner-full-name-kana"
      />
      <div
        class="error-message owner-full-name-kana"
        data-test="error-message-owner-full-name-kana"
      >
        {{ isInvalidOwnerFullNameKana(item.ownerFullNameKana) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0">飼主の性別</label>
      <input
        :class="{ error: errorOwnerSexName }"
        :value="item.ownerSexName"
        :disabled="!item.isValid"
        maxlength="3"
        @input="input($event, 'ownerSexName')"
        data-test="input-owner-sex-name"
      />
      <div
        class="error-message owner-sex-name"
        data-test="error-message-owner-sex-name"
      >
        {{ isInvalidOwnerSexName(item.ownerSexName) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0">飼主の生年月日</label>
      <input
        :class="{ error: errorOwnerBirthday }"
        :value="item.ownerBirthday"
        :disabled="!item.isValid"
        maxlength="10"
        @input="input($event, 'ownerBirthday')"
        data-test="input-owner-birthday"
      />
      <div
        class="error-message owner-birthday"
        data-test="error-message-owner-birthday"
      >
        {{ isInvalidOwnerBirthday(item.ownerBirthday) || '' }}
      </div>
    </div>

    <div class="form">
      <label v-if="index === 0">郵便番号</label>
      <input
        :class="{ error: errorPostalCode }"
        :value="item.postalCode"
        :disabled="!item.isValid"
        maxlength="8"
        @input="input($event, 'postalCode')"
        data-test="input-postal-code"
      />
      <div
        class="error-message postal-code"
        data-test="error-message-postal-code"
      >
        {{ isInvalidPostalCode(item.postalCode) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">都道府県</label>
      <input
        :class="{ error: errorPrefectureName }"
        :value="item.prefectureName"
        :disabled="!item.isValid"
        maxlength="5"
        @input="input($event, 'prefectureName')"
        data-test="input-prefecture-name"
      />
      <div
        class="error-message prefecture-name"
        data-test="error-message-prefecture-name"
      >
        {{ isInvalidPrefectureName(item.prefectureName) || '' }}
      </div>
    </div>
    <div class="form address">
      <label v-if="index === 0">市区町村</label>
      <input
        class="address"
        :class="{ error: errorAddress }"
        :value="item.address"
        :disabled="!item.isValid"
        maxlength="50"
        @input="input($event, 'address')"
        data-test="input-address"
      />
      <div class="error-message address" data-test="error-message-address">
        {{ isInvalidAddress(item.address) || '' }}
      </div>
    </div>
    <div class="form building">
      <label v-if="index === 0">ビル・マンション名</label>
      <input
        class="building"
        :class="{ error: errorBuilding }"
        :value="item.building"
        :disabled="!item.isValid"
        maxlength="50"
        @input="input($event, 'building')"
        data-test="input-building"
      />
      <div class="error-message building" data-test="error-message-building">
        {{ isInvalidBuilding(item.building) || '' }}
      </div>
    </div>

    <div class="form tel">
      <label v-if="index === 0">電話番号（半角数字）</label>
      <input
        class="tel"
        :class="{ error: errorTel }"
        :value="item.tel"
        :disabled="!item.isValid"
        maxlength="13"
        @input="input($event, 'tel')"
        data-test="input-tel"
      />
      <div class="error-message tel" data-test="error-message-tel">
        {{ isInvalidTel(item.tel) || '' }}
      </div>
    </div>
    <div class="form email">
      <label v-if="index === 0">メールアドレス</label>
      <input
        class="email"
        :class="{ error: errorEmail }"
        :value="item.email"
        :disabled="!item.isValid"
        maxlength="254"
        @input="input($event, 'email')"
        data-test="input-email"
      />
      <div class="error-message email" data-test="error-message-email">
        {{ isInvalidEmail(item.email) || '' }}
      </div>
    </div>
    <div class="form owner-note">
      <label v-if="index === 0">飼主の備考</label>
      <textarea
        class="owner-note"
        :class="{ error: errorOwnerNote }"
        :value="item.ownerNote"
        :disabled="!item.isValid"
        @input="input($event, 'ownerNote')"
        data-test="input-owner-note"
      />
      <div
        class="error-message owner-note"
        data-test="error-message-owner-note"
      >
        {{ isInvalidOwnerNote(item.ownerNote) || '' }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  VALID_SHOW_ID_EMPTY_REGEX,
  VALID_NUMERIC_STRING_REGEX,
  VALID_KATAKANA_EMPTY_REGEX,
  VALID_SURROGATE_PAIR_REGEX,
  VALID_DATE_EMPTY_REGEX,
  VALID_DATE_SLASH_ZERO_REGEX,
  VALID_DATE_SLASH_NO_ZERO_REGEX,
  VALID_YEAR_MONTH_REGEX,
  VALID_YEAR_MONTH_SLASH_REGEX,
  VALID_YEAR_REGEX,
  VALID_ANICOM_C_ID_REGEX,
  VALID_FULL_NAME_REGEX,
  VALID_FULL_NAME_KANA_REGEX,
  VALID_POSTALCODE_EMPTY_REGEX,
  VALID_POSTALCODE_HYPHEN_REGEX,
  VALID_TEL_EMPTY_REGEX,
  VALID_TEL_HYPHEN_REGEX,
  VALID_EMAIL_EMPTY_REGEX,
  VALID_KATAKANA_REGEX,
  INSURANCES_EMPTY,
  IPET_PLANS,
  DOCOMO_PLANS,
  STRING_RATES_BY_PLAN
} from '@/utils/define'
import { convertVariousDateFormatToYYYYMMDD } from '@/utils/moment'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'DataImportPetoreluPlusForm',

  props: {
    item: { type: Object },
    index: { type: Number },
    ownerShowIdGroupObj: { type: Object },
    patientShowIdGroupObj: { type: Object },
    mcCodeGroupObj: { type: Object },
    emailGroupObj: { type: Object },
    storePatientShowIdKeyObj: { type: Object },
    storePatientMcCodeKeyObj: { type: Object },
    storeOwnerShowIdKeyObj: { type: Object },
    storeOwnerEmailKeyObj: { type: Object },
    patientSexNamesZero: { type: Array },
    speciesNames: { type: Array },
    prefectureNamesZero: { type: Array },
    priority: { type: Number }
  },

  data() {
    return {
      invalidFlg: {
        patientShowId: false,
        mcCode: false,
        patientName: false,
        patientNameKana: false,
        patientSexName: false,
        hairColor: false,
        speciesName: false,
        breed: false,
        patientBirthday: false,
        anicomCId: false,
        patientNote: false,
        ownerShowId: false,
        ownerFullName: false,
        ownerFullNameKana: false,
        ownerSexName: false,
        ownerBirthday: false,
        postalCode: false,
        prefectureName: false,
        address: false,
        building: false,
        tel: false,
        email: false,
        ownerNote: false
      },
      errorPatientShowId: false,
      errorMcCode: false,
      errorPatientName: false,
      errorPatientNameKana: false,
      errorPatientSexName: false,
      errorHairColor: false,
      errorSpeciesName: false,
      errorBreed: false,
      errorPatientBirthday: false,
      errorInsurance: false,
      errorAnicomCId: false,
      errorPolicyNo: false,
      errorPlanNm: false,
      errorClaimRate: false,
      errorStartDate: false,
      errorEndDate: false,
      errorFirstContractDate: false,
      errorPatientNote: false,
      errorOwnerShowId: false,
      errorOwnerFullName: false,
      errorOwnerFullNameKana: false,
      errorOwnerSexName: false,
      errorOwnerBirthday: false,
      errorPostalCode: false,
      errorPrefectureName: false,
      errorAddress: false,
      errorBuilding: false,
      errorTel: false,
      errorEmail: false,
      errorOwnerNote: false
    }
  },

  computed: {
    sameShowIdPatientInDatabase() {
      return this.storePatientShowIdKeyObj[this.item.patientShowId]
    },
    patientShowIdInfo() {
      return this.sameShowIdPatientInDatabase ? '登録済み患者' : '新規患者'
    },
    sameShowIdOwnerInDatabase() {
      return this.storeOwnerShowIdKeyObj[this.item.ownerShowId]
    },
    ownerShowIdInfo() {
      return this.sameShowIdOwnerInDatabase ? '登録済み飼主' : '新規飼主'
    },
    hasAnicomInsurance() {
      return this.item.insurance === 'anicom'
    },
    hasIpetOrDocomoInsurance() {
      return this.item.insurance === 'ipet' || this.item.insurance === 'docomo'
    },
    isInvalidPatientShowId: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : value.length === 0
          ? '入力必須項目です　'
          : !VALID_SHOW_ID_EMPTY_REGEX.test(value)
          ? '半角数字かハイフン(-)のみで入力して下さい　'
          : this.patientShowIdGroupObj[value].length > 1
          ? '重複する患者IDが存在します　'
          : value.length > 20
          ? '20文字以内で入力して下さい　'
          : this.priority === 1 && //既存優先
            this.sameShowIdPatientInDatabase && //登録済み患者
            !this.sameShowIdOwnerInDatabase //新規飼主
          ? '飼主だけを新規で登録することはできません。登録したい場合、患者IDを変更して新規患者にして下さい '
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'patientShowId')
        return result
      }
    },
    isInvalidMcCode: function() {
      return function(value) {
        const sameMcCodePatientInDatabase = this.storePatientMcCodeKeyObj[value]
        const result = !this.item.isValid
          ? false
          : !VALID_NUMERIC_STRING_REGEX(15).test(value)
          ? '15桁の半角数字を入力してください　'
          : (this.priority === 2 && //上書き
              sameMcCodePatientInDatabase &&
              sameMcCodePatientInDatabase.showId !== this.item.patientShowId) ||
            (this.priority === 1 && //既存優先
            !this.storePatientShowIdKeyObj[this.item.patientShowId] && //新規患者の場合
              sameMcCodePatientInDatabase &&
              sameMcCodePatientInDatabase.showId !== this.item.patientShowId)
          ? 'このMC識別番号は医院内で既に使用されております　'
          : value.length > 0 && this.mcCodeGroupObj[value].length > 1
          ? '重複するMC識別番号が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'mcCode')
        return result
      }
    },
    isInvalidPatientName: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : !value || !value.match(/\S/g)
          ? '入力必須項目です　'
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : value.length > 30
          ? '30文字以内で入力して下さい　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'patientName')
        return result
      }
    },
    isInvalidPatientNameKana: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : value.length > 30
          ? '30文字以内で入力して下さい　'
          : !VALID_KATAKANA_EMPTY_REGEX.test(value)
          ? '全角カナで入力して下さい　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'patientNameKana')
        return result
      }
    },
    isInvalidPatientSexName: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : !this.patientSexNamesZero.includes(value)
          ? `${this.patientSexNamesZero
              .filter(v => v !== '')
              .join('・')}の中から入力して下さい` + '　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'patientSexName')
        return result
      }
    },
    isInvalidHairColor: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : value.length > 50
          ? '50文字以内で入力して下さい　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'hairColor')
        return result
      }
    },
    isInvalidSpeciesName: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : !this.speciesNames.includes(value)
          ? `${this.speciesNames.join('・')}の中から入力して下さい` + '　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'speciesName')
        return result
      }
    },
    isInvalidBreed: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : value.length > 50
          ? '50文字以内で入力して下さい　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'breed')
        return result
      }
    },
    isInvalidPatientBirthday: function() {
      return function(value) {
        const hasInsurance =
          this.hasAnicomInsurance || this.hasIpetOrDocomoInsurance
        const isInvalidYearMonth =
          !VALID_YEAR_MONTH_REGEX.test(value) &&
          !VALID_YEAR_MONTH_SLASH_REGEX.test(value)
        const isInvalidYear = !VALID_YEAR_REGEX.test(value)
        const result = !this.item.isValid
          ? false
          : hasInsurance && value.length === 0
          ? '保険に加入している場合は、入力必須項目です　'
          : hasInsurance && this.isInvalidDate(value)
          ? '年月日(半角数字)を入力して下さい(例. 2022年6月1日の場合→20220601または2022/06/01または2022/6/1)　'
          : !hasInsurance &&
            this.isInvalidDate(value) &&
            isInvalidYearMonth &&
            isInvalidYear
          ? '年月日(半角数字)を入力して下さい(例. 2022年6月1日の場合→20220601または2022/06/01または2022/6/1)、年月または年のみの値も登録できます(例. 202206、2022/06、2022/6、2022)　'
          : value.slice(0, 4) > moment().format('YYYY')
          ? '年は今年以前の年を入力して下さい　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'patientBirthday')
        return result
      }
    },
    isInvalidInsurance: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : !INSURANCES_EMPTY.includes(value)
          ? `${INSURANCES_EMPTY.filter(v => v !== '').join(
              '・'
            )}の中から入力して下さい（未加入の場合は未入力にして下さい）` + '　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'insurance')
        return result
      }
    },
    isInvalidAnicomCId: function() {
      return function(value) {
        const result =
          !this.item.isValid || !this.hasAnicomInsurance
            ? false
            : value.length === 0
            ? '入力必須項目です　'
            : !VALID_ANICOM_C_ID_REGEX.test(value)
            ? '半角英数字で1桁目はアルファベット、2~9桁目は数字、10桁目は数字またはアルファベットを入力してください　'
            : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'anicomCId')
        return result
      }
    },
    isInvalidPolicyNo: function() {
      return function(value) {
        const result =
          !this.item.isValid || !this.hasIpetOrDocomoInsurance
            ? false
            : value.length === 0
            ? '入力必須項目です　'
            : !VALID_NUMERIC_STRING_REGEX(10).test(value)
            ? '10桁の半角数字を入力してください　'
            : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'policyNo')
        return result
      }
    },
    isInvalidPlanNm: function() {
      return function(value) {
        const result =
          !this.item.isValid || !this.hasIpetOrDocomoInsurance
            ? false
            : value.length === 0
            ? '入力必須項目です　'
            : this.item.insurance === 'ipet' && !IPET_PLANS.includes(value)
            ? `${IPET_PLANS.join('・')}の中から入力して下さい` + '　'
            : this.item.insurance === 'docomo' && value !== DOCOMO_PLANS[0]
            ? `${DOCOMO_PLANS[0]}、という商品名を入力して下さい` + '　'
            : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'planNm')
        return result
      }
    },
    isInvalidClaimRate: function() {
      return function(value) {
        const rates = STRING_RATES_BY_PLAN[this.item.planNm]
        const result =
          !this.item.isValid || !this.hasIpetOrDocomoInsurance
            ? false
            : value.length === 0
            ? '入力必須項目です　'
            : !rates
            ? '先に適切なアイペット/商品名を入力して下さい　'
            : !rates.includes(value)
            ? `${rates.join(
                '・'
              )}の中から入力して下さい（「%」はつけないで下さい）` + '　'
            : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'claimRate')
        return result
      }
    },
    isInvalidStartDate: function() {
      return function(value) {
        const result =
          !this.item.isValid || !this.hasIpetOrDocomoInsurance
            ? false
            : value.length === 0
            ? '入力必須項目です　'
            : this.isInvalidDate(value)
            ? '年月日(半角数字)を入力して下さい(例. 2022年6月1日の場合→20220601または2022/06/01または2022/6/1)　'
            : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'startDate')
        return result
      }
    },
    isInvalidEndDate: function() {
      return function(value) {
        const result =
          !this.item.isValid || !this.hasIpetOrDocomoInsurance
            ? false
            : value.length === 0
            ? '入力必須項目です　'
            : this.isInvalidDate(value)
            ? '年月日(半角数字)を入力して下さい(例. 2022年6月1日の場合→20220601または2022/06/01または2022/6/1)　'
            : convertVariousDateFormatToYYYYMMDD(this.item.startDate) >
              convertVariousDateFormatToYYYYMMDD(value)
            ? '終了日は保険期間の開始日以降の日付にして下さい　'
            : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'endDate')
        return result
      }
    },
    isInvalidFirstContractDate: function() {
      return function(value) {
        const result =
          !this.item.isValid || !this.hasIpetOrDocomoInsurance
            ? false
            : this.isInvalidDate(value)
            ? '年月日(半角数字)を入力して下さい(例. 2022年6月1日の場合→20220601または2022/06/01または2022/6/1)　'
            : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'firstContractDate')
        return result
      }
    },
    isInvalidPatientNote: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'patientNote')
        return result
      }
    },
    isInvalidOwnerShowId: function() {
      return function(value) {
        let sameOwnerShowIdDeferentOwners = []
        if (this.item.ownerShowId !== '') {
          const ownerShowIds = this.ownerShowIdGroupObj[this.item.ownerShowId]
          if (ownerShowIds !== undefined) {
            sameOwnerShowIdDeferentOwners = ownerShowIds.filter(v => {
              return (
                v.ownerFullName !== this.item.ownerFullName ||
                v.ownerFullNameKana !== this.item.ownerFullNameKana ||
                v.ownerSexName !== this.item.ownerSexName ||
                v.ownerBirthday !== this.item.ownerBirthday ||
                v.postalCode !== this.item.postalCode ||
                v.prefectureName !== this.item.prefectureName ||
                v.address !== this.item.address ||
                v.building !== this.item.building ||
                v.tel !== this.item.tel ||
                v.email !== this.item.email ||
                v.ownerNote !== this.item.ownerNote
              )
            })
          }
        }
        const result = !this.item.isValid
          ? false
          : value.length === 0
          ? '入力必須項目です　'
          : !VALID_SHOW_ID_EMPTY_REGEX.test(value)
          ? '半角数字かハイフン(-)のみで入力して下さい　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで飼主情報が異なる行が存在します　'
          : value.length > 20
          ? '20文字以内で入力して下さい　'
          : this.priority === 2 && //上書き
            this.sameShowIdPatientInDatabase && //登録済み患者
            this.sameShowIdPatientInDatabase.ownerShowId !== value
          ? `登録済み患者を上書きしたい場合、飼主IDは登録済み患者の飼主のID(${this.sameShowIdPatientInDatabase.ownerShowId})にして下さい `
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'ownerShowId')
        return result
      }
    },
    isInvalidOwnerFullName: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'ownerFullName'
        )
        const result = !this.item.isValid
          ? false
          : value.length === 0
          ? '入力必須項目です　'
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : !VALID_FULL_NAME_REGEX.test(value)
          ? '苗字と名前を入力し、全角スペースで区切って下さい(例. 佐藤　太郎) また苗字と名前はそれぞれ15文字以内でひらがな・カタカナ・漢字・アルファベット・数字のみで入力して下さい　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで飼主名が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'ownerFullName')
        return result
      }
    },
    isInvalidOwnerFullNameKana: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'ownerFullNameKana'
        )
        const result = !this.item.isValid
          ? false
          : value.length === 0
          ? '入力必須項目です　'
          : !VALID_KATAKANA_REGEX.test(value.replace(/\s/g, ''))
          ? '全角カタカナで入力して下さい　'
          : !VALID_FULL_NAME_KANA_REGEX.test(value)
          ? 'ミョウジとナマエを入力し、全角スペースで区切って下さい(例. サトウ　タロウ) またミョウジとナマエはそれぞれ15文字以内で入力して下さい　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで飼主名（全角カナ）が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'ownerFullNameKana')
        return result
      }
    },
    isInvalidOwnerSexName: function() {
      return function(value) {
        const ownerSexNamesZero = ['', '男性', '女性', 'その他']
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'ownerSexName'
        )
        const result = !this.item.isValid
          ? false
          : !ownerSexNamesZero.includes(value)
          ? `${ownerSexNamesZero
              .filter(v => v !== '')
              .join('・')}の中から入力して下さい` + '　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで飼主の性別が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'ownerSexName')
        return result
      }
    },
    isInvalidOwnerBirthday: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'ownerBirthday'
        )
        const result = !this.item.isValid
          ? false
          : this.isInvalidDate(value)
          ? '年月日(半角数字)を入力して下さい(例. 2022年6月1日の場合→20220601または2022/06/01または2022/6/1)　'
          : value.slice(0, 4) > moment().format('YYYY')
          ? '年は今年以前の年を入力して下さい　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで飼主の生年月日が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'ownerBirthday')
        return result
      }
    },
    isInvalidPostalCode: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'postalCode'
        )
        const result = !this.item.isValid
          ? false
          : !VALID_POSTALCODE_EMPTY_REGEX.test(value) &&
            !VALID_POSTALCODE_HYPHEN_REGEX.test(value)
          ? '有効な郵便番号(7桁の半角数字または3桁の半角数字-4桁の半角数字)を入力して下さい(例. 2130026または213-0026)　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで郵便番号が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'postalCode')
        return result
      }
    },
    isInvalidPrefectureName: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'prefectureName'
        )
        const result = !this.item.isValid
          ? false
          : !this.prefectureNamesZero.includes(value)
          ? '都道府県の情報を入力して下さい(例. 東京都、神奈川県)　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで都道府県が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'prefectureName')
        return result
      }
    },
    isInvalidAddress: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'address'
        )
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : value.length > 50
          ? '50文字以内で入力して下さい　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで市区町村が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'address')
        return result
      }
    },
    isInvalidBuilding: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'building'
        )
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : value.length > 50
          ? '50文字以内で入力して下さい　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じでビル・マンション名が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'building')
        return result
      }
    },
    isInvalidTel: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'tel'
        )
        const result = !this.item.isValid
          ? false
          : !VALID_TEL_EMPTY_REGEX.test(value) &&
            !VALID_TEL_HYPHEN_REGEX.test(value)
          ? '有効な電話番号(半角英数字)を入力して下さい(例. 09012345678または090-1234-5678)　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで電話番号が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'tel')
        return result
      }
    },
    isInvalidEmail: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'email'
        )
        const sameEmailOwnerInDatabase = this.storeOwnerEmailKeyObj[value]
        const sameEmailOwners = this.emailGroupObj[value]
        const localPart = value.slice(0, value.lastIndexOf('@'))
        const result = !this.item.isValid
          ? false
          : !(
              VALID_EMAIL_EMPTY_REGEX.test(value) &&
              localPart.length <= 64 &&
              value.length <= 254
            )
          ? '有効なメールアドレス(半角英数字)を入力して下さい(例. petorelu@email.com)　'
          : (this.priority === 2 && //上書き
              sameEmailOwnerInDatabase &&
              sameEmailOwnerInDatabase.showId !== this.item.ownerShowId) ||
            (this.priority === 1 && //既存優先
            !this.storeOwnerShowIdKeyObj[this.item.ownerShowId] && //新規飼主の場合
              sameEmailOwnerInDatabase &&
              sameEmailOwnerInDatabase.showId !== this.item.ownerShowId)
          ? 'このメールアドレスは医院内で既に使用されております　'
          : value.length > 0 &&
            sameEmailOwners &&
            sameEmailOwners.some(
              owner => owner.ownerShowId !== this.item.ownerShowId
            )
          ? '飼主IDが異なる複数の飼主で同じメールアドレスが使用されております　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じでメールアドレスが異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'email')
        return result
      }
    },
    isInvalidOwnerNote: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'ownerNote'
        )
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '飼主IDが同じで飼主の備考が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'ownerNote')
        return result
      }
    }
  },

  methods: {
    isInvalidDate(value) {
      return (
        !VALID_DATE_EMPTY_REGEX.test(value) &&
        !VALID_DATE_SLASH_ZERO_REGEX.test(value) &&
        !VALID_DATE_SLASH_NO_ZERO_REGEX.test(value)
      )
    },
    makeSameOwnerShowIdDeferentOwners(ownerShowId, ownerShowIdGroupObj, key) {
      if (ownerShowId !== '') {
        const ownerShowIds = ownerShowIdGroupObj[ownerShowId]
        if (ownerShowIds !== undefined) {
          return ownerShowIds.filter(v => v[key] !== this.item[key])
        }
      } else {
        return []
      }
    },
    checkInvalid(invalid, key) {
      this.invalidFlg[key] = invalid
      this.$emit('check', {
        invalid: Object.values(this.invalidFlg).some(v => v === true),
        index: this.index
      })
      switch (key) {
        case 'patientShowId':
          this.errorPatientShowId = invalid
          break
        case 'mcCode':
          this.errorMcCode = invalid
          break
        case 'patientName':
          this.errorPatientName = invalid
          break
        case 'patientNameKana':
          this.errorPatientNameKana = invalid
          break
        case 'patientSexName':
          this.errorPatientSexName = invalid
          break
        case 'hairColor':
          this.errorHairColor = invalid
          break
        case 'speciesName':
          this.errorSpeciesName = invalid
          break
        case 'breed':
          this.errorBreed = invalid
          break
        case 'patientBirthday':
          this.errorPatientBirthday = invalid
          break
        case 'insurance':
          this.errorInsurance = invalid
          break
        case 'anicomCId':
          this.errorAnicomCId = invalid
          break
        case 'policyNo':
          this.errorPolicyNo = invalid
          break
        case 'planNm':
          this.errorPlanNm = invalid
          break
        case 'claimRate':
          this.errorClaimRate = invalid
          break
        case 'startDate':
          this.errorStartDate = invalid
          break
        case 'endDate':
          this.errorEndDate = invalid
          break
        case 'firstContractDate':
          this.errorFirstContractDate = invalid
          break
        case 'patientNote':
          this.errorPatientNote = invalid
          break
        case 'ownerShowId':
          this.errorOwnerShowId = invalid
          break
        case 'ownerFullName':
          this.errorOwnerFullName = invalid
          break
        case 'ownerFullNameKana':
          this.errorOwnerFullNameKana = invalid
          break
        case 'ownerSexName':
          this.errorOwnerSexName = invalid
          break
        case 'ownerBirthday':
          this.errorOwnerBirthday = invalid
          break
        case 'postalCode':
          this.errorPostalCode = invalid
          break
        case 'prefectureName':
          this.errorPrefectureName = invalid
          break
        case 'address':
          this.errorAddress = invalid
          break
        case 'building':
          this.errorBuilding = invalid
          break
        case 'tel':
          this.errorTel = invalid
          break
        case 'email':
          this.errorEmail = invalid
          break
        case 'ownerNote':
          this.errorOwnerNote = invalid
          break
      }
    },
    change(e) {
      const value = e.target.value === 'true' ? true : false
      this.$emit('input', {
        value,
        key: 'isValid',
        index: this.index,
        before: _.cloneDeep(this.item)
      })
    },
    input(e, key) {
      this.$emit('input', {
        value: e.target.value,
        key,
        index: this.index,
        before: _.cloneDeep(this.item)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.data-import-petorelu-plus-form {
  display: flex;
  > .form {
    position: relative;
    box-sizing: border-box;
    width: 115px;
    margin-right: 5px;
    margin-bottom: 18px;
    &.mc-code {
      width: 175px;
    }
    &.patient-name-kana {
      width: 140px;
    }
    &.insurance {
      width: 130px;
    }
    &.anicom-c-id {
      width: 140px;
    }
    &.policy-no {
      width: 160px;
    }
    &.plan-nm {
      width: 155px;
    }
    &.claim-rate {
      width: 185px;
    }
    &.start-date {
      width: 220px;
    }
    &.end-date {
      width: 220px;
    }
    &.first-contract-date {
      width: 185px;
    }
    &.patient-note {
      width: 250px;
    }
    &.owner-full-name-kana {
      width: 175px;
    }
    &.address {
      width: 145px;
    }
    &.building {
      width: 145px;
    }
    &.tel {
      width: 150px;
    }
    &.email {
      width: 300px;
    }
    &.owner-note {
      width: 250px;
    }
    > label {
      font-size: 15px;
      > .required {
        font-size: 13px;
        color: #{$tomato};
      }
    }
    > select {
      display: inline-block;
      box-sizing: border-box;
      width: 110px;
      height: 33px;
    }
    > input {
      box-sizing: border-box;
      width: 110px;
      height: 33px;
      &:disabled {
        background-color: #{$light-grey};
        color: #{$brown-gray};
        opacity: 0.7;
      }
      &.mc-code {
        width: 170px;
      }
      &.patient-name-kana {
        width: 135px;
      }
      &.insurance {
        width: 125px;
      }
      &.anicom-c-id {
        width: 135px;
      }
      &.policy-no {
        width: 155px;
      }
      &.plan-nm {
        width: 150px;
      }
      &.claim-rate {
        width: 180px;
      }
      &.start-date {
        width: 215px;
      }
      &.end-date {
        width: 215px;
      }
      &.first-contract-date {
        width: 180px;
      }
      &.owner-full-name-kana {
        width: 170px;
      }
      &.address {
        width: 140px;
      }
      &.building {
        width: 140px;
      }
      &.tel {
        width: 145px;
      }
      &.email {
        width: 295px;
      }
    }
    > textarea {
      box-sizing: border-box;
      width: 245px;
      height: 33px;
      min-height: 33px;
      resize: vertical;
    }
    > .patient-show-id-info {
      font-size: 14px;
    }
    > .owner-show-id-info {
      font-size: 14px;
    }
    > .error {
      background: pink;
    }
    > .error-message {
      position: absolute;
      font-size: 12px;
      color: #{$tomato};
      background: #{$white};
      white-space: nowrap;
      $array: 'patient-show-id', 'mc-code', 'patient-name', 'patient-name-kana',
        'patient-sex-name', 'hair-color', 'species-name', 'breed',
        'patient-birthday', 'insurance', 'anicom-c-id', 'policy-no', 'plan-nm',
        'claim-rate', 'start-date', 'end-date', 'first-contract-date',
        'patient-note', 'owner-show-id', 'owner-full-name',
        'owner-full-name-kana', 'owner-sex-name', 'owner-birthday',
        'postal-code', 'prefecture-name', 'address', 'building', 'tel', 'email',
        'owner-note';
      $form-length: length($array);
      @each $class in $array {
        $index: index($array, $class);
        &.#{$class} {
          z-index: $form-length - $index + 1;
        }
      }
    }
  }
}
</style>

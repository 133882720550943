<template>
  <div class="data-import-ainicom-receptor-form">
    <div class="form">
      <label v-if="index === 0">有効/無効</label>
      <select :value="item.isValid" @change="change">
        <option :value="true">有効</option>
        <option :value="false">無効</option>
      </select>
    </div>
    <div class="form">
      <label v-if="index === 0">カルテ番号</label>
      <input
        :class="{ error: errorMedicalNum }"
        :value="item.medicalNum"
        :disabled="!item.isValid"
        maxlength="20"
        @input="input($event, 'medicalNum')"
        data-test="input-medical-num"
      />
      <div class="owner-show-id-info">{{ ownerShowIdInfo }}</div>
      <div class="patient-show-id-info">{{ patientShowIdInfo }}</div>
      <div
        class="error-message medicalNum"
        data-test="error-message-medical-num"
      >
        {{ isInvalidMedicalNum(item.medicalNum) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">患者名</label>
      <input
        :class="{ error: errorPatientName }"
        :value="item.patientName"
        :disabled="!item.isValid"
        maxlength="30"
        @input="input($event, 'patientName')"
      />
      <div
        class="error-message patient-name"
        data-test="error-message-patient-name"
      >
        {{ isInvalidPatientName(item.patientName) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">種別</label>
      <input
        :class="{ error: errorSpeciesName }"
        :value="item.speciesName"
        :disabled="!item.isValid"
        @input="input($event, 'speciesName')"
      />
      <div
        class="error-message species-name"
        data-test="error-message-species-name"
      >
        {{ isInvalidSpeciesName(item.speciesName) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">品種</label>
      <input
        :class="{ error: errorBreed }"
        :value="item.breed"
        :disabled="!item.isValid"
        maxlength="50"
        @input="input($event, 'breed')"
      />
      <div class="error-message breed" data-test="error-message-breed">
        {{ isInvalidBreed(item.breed) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">飼主名</label>
      <input
        :class="{ error: errorOwnerFullName }"
        :value="item.ownerFullName"
        :disabled="!item.isValid"
        maxlength="31"
        @input="input($event, 'ownerFullName')"
      />
      <div
        class="error-message owner-full-name"
        data-test="error-message-owner-full-name"
      >
        {{ isInvalidOwnerFullName(item.ownerFullName) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">フリガナ</label>
      <input
        :class="{ error: errorOwnerFullNameKana }"
        :value="item.ownerFullNameKana"
        :disabled="!item.isValid"
        maxlength="31"
        @input="input($event, 'ownerFullNameKana')"
      />
      <div
        class="error-message owner-full-name-kana"
        data-test="error-message-owner-full-name-kana"
      >
        {{ isInvalidOwnerFullNameKana(item.ownerFullNameKana) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">郵便番号</label>
      <input
        :class="{ error: errorPostalCode }"
        :value="item.postalCode"
        :disabled="!item.isValid"
        maxlength="7"
        @input="input($event, 'postalCode')"
      />
      <div
        class="error-message postal-code"
        data-test="error-message-postal-code"
      >
        {{ isInvalidPostalCode(item.postalCode) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">住所</label>
      <input
        :class="{ error: errorFullAddress }"
        :value="item.fullAddress"
        :disabled="!item.isValid"
        maxlength="50"
        @input="input($event, 'fullAddress')"
      />
      <div
        class="error-message full-address"
        data-test="error-message-full-address"
      >
        {{ isInvalidFullAddress(item.fullAddress) || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">自宅番号</label>
      <input
        :class="{ error: errorHomeTel }"
        :value="item.homeTel"
        :disabled="!item.isValid"
        maxlength="20"
        @input="input($event, 'homeTel')"
      />
      <div class="error-message home-tel" data-test="error-message-home-tel">
        {{ isInvalidTel(item.homeTel, 'homeTel') || '' }}
      </div>
    </div>
    <div class="form">
      <label v-if="index === 0">携帯番号</label>
      <input
        :class="{ error: errorMobileTel }"
        :value="item.mobileTel"
        :disabled="!item.isValid"
        maxlength="20"
        @input="input($event, 'mobileTel')"
      />
      <div class="error-message" data-test="error-message-mobile-tel">
        {{ isInvalidTel(item.mobileTel, 'mobileTel') || '' }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  VALID_MEDICAL_RECORD_NUMBER_REGEX,
  VALID_SURROGATE_PAIR_REGEX,
  VALID_FULL_NAME_REGEX,
  VALID_FULL_NAME_KANA_REGEX,
  VALID_POSTALCODE_EMPTY_REGEX,
  VALID_ZERO_REGEX,
  VALID_KATAKANA_REGEX
} from '@/utils/define'
import _ from 'lodash'

export default {
  name: 'DataImportAnicomReceptorForm',

  props: {
    item: { type: Object },
    index: { type: Number },
    medicalNumGroupObj: { type: Object },
    ownerShowIdGroupObj: { type: Object },
    storePatientShowIdKeyObj: { type: Object },
    storeOwnerShowIdKeyObj: { type: Object },
    priority: { type: Number }
  },

  data() {
    return {
      invalidFlg: {
        medicalNum: false,
        patientName: false,
        speciesName: false,
        breed: false,
        ownerFullName: false,
        ownerFullNameKana: false,
        postalCode: false,
        fullAddress: false,
        homeTel: false,
        mobileTel: false
      },
      errorMedicalNum: false,
      errorPatientName: false,
      errorSpeciesName: false,
      errorBreed: false,
      errorOwnerFullName: false,
      errorOwnerFullNameKana: false,
      errorPostalCode: false,
      errorFullAddress: false,
      errorHomeTel: false,
      errorMobileTel: false
    }
  },

  computed: {
    speciesNames() {
      const species = this.$store.getters['species/getData']
      return species.map(v => v.name)
    },
    ownerShowId() {
      return this.item.medicalNum.split('-')[0]
    },
    sameShowIdOwnerInDatabase() {
      return this.storeOwnerShowIdKeyObj[this.ownerShowId]
    },
    ownerShowIdInfo() {
      const id = '飼主ID:' + this.ownerShowId + ' → '
      return this.sameShowIdOwnerInDatabase
        ? id + '登録済み飼主'
        : id + '新規飼主'
    },
    sameShowIdPatientInDatabase() {
      return this.storePatientShowIdKeyObj[this.item.medicalNum]
    },
    patientShowIdInfo() {
      const id = '患者ID:' + this.item.medicalNum + ' → '
      return this.sameShowIdPatientInDatabase
        ? id + '登録済み患者'
        : id + '新規患者'
    },
    isInvalidMedicalNum: function() {
      return function(value) {
        let sameOwnerShowIdDeferentOwners = []
        if (this.item.ownerShowId !== '') {
          const ownerShowIds = this.ownerShowIdGroupObj[this.item.ownerShowId]
          if (ownerShowIds !== undefined) {
            sameOwnerShowIdDeferentOwners = ownerShowIds.filter(v => {
              return (
                v.ownerFullName !== this.item.ownerFullName ||
                v.ownerFullNameKana !== this.item.ownerFullNameKana ||
                v.postalCode !== this.item.postalCode ||
                v.fullAddress !== this.item.fullAddress ||
                v.homeTel !== this.item.homeTel ||
                v.mobileTel !== this.item.mobileTel
              )
            })
          }
        }
        const ownerShowId = value.split('-')[0]
        const result = !this.item.isValid
          ? false
          : value.length === 0
          ? '入力必須項目です　'
          : !VALID_MEDICAL_RECORD_NUMBER_REGEX.test(value)
          ? '半角数字2つをハイフン(-)で区切って下さい(例. 1000-01)　'
          : VALID_ZERO_REGEX.test(value)
          ? '上4桁0000は利用できません　'
          : this.medicalNumGroupObj[value].length > 1
          ? '重複するカルテ番号が存在します　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? '上桁が同じで飼主情報が異なる行が存在します　'
          : value.length > 20
          ? '20文字以内で入力して下さい　'
          : this.priority === 2 && //上書き
            this.sameShowIdPatientInDatabase && //登録済み患者
            this.sameShowIdPatientInDatabase.ownerShowId !== ownerShowId
          ? `登録済み患者を上書きしたい場合、飼主IDは登録済み患者の飼主のID(${this.sameShowIdPatientInDatabase.ownerShowId})にして下さい `
          : this.priority === 1 && //既存優先
            this.sameShowIdPatientInDatabase && //登録済み患者
            !this.sameShowIdOwnerInDatabase //新規飼主
          ? '飼主だけを新規で登録することはできません。登録したい場合、患者IDを変更して新規患者にして下さい '
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'medicalNum')
        return result
      }
    },
    isInvalidPatientName: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : !value || !value.match(/\S/g)
          ? '入力必須項目です　'
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : value.length > 30
          ? '30文字以内で入力して下さい　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'patientName')
        return result
      }
    },
    isInvalidSpeciesName: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : !this.speciesNames.includes(value)
          ? `${this.speciesNames.join('・')}の中から入力して下さい` + '　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'speciesName')
        return result
      }
    },
    isInvalidBreed: function() {
      return function(value) {
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : value.length > 50
          ? '50文字以内で入力して下さい　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'breed')
        return result
      }
    },
    isInvalidOwnerFullName: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'ownerFullName'
        )
        const result = !this.item.isValid
          ? false
          : value.length === 0
          ? '入力必須項目です　'
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : !VALID_FULL_NAME_REGEX.test(value)
          ? '苗字と名前を入力し、全角スペースで区切って下さい(例. 佐藤　太郎) また苗字と名前はそれぞれ15文字以内でひらがな・カタカナ・漢字・アルファベット・数字のみで入力して下さい　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? 'カルテ番号の上桁が同じで飼主名が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'ownerFullName')
        return result
      }
    },
    isInvalidOwnerFullNameKana: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'ownerFullNameKana'
        )
        const result = !this.item.isValid
          ? false
          : value.length === 0
          ? '入力必須項目です　'
          : !VALID_KATAKANA_REGEX.test(value.replace(/\s/g, ''))
          ? '全角カタカナで入力して下さい　'
          : !VALID_FULL_NAME_KANA_REGEX.test(value)
          ? 'ミョウジとナマエを入力し、全角スペースで区切って下さい(例. サトウ　タロウ) またミョウジとナマエはそれぞれ15文字以内で入力して下さい　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? 'カルテ番号の上桁が同じでフリガナが異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'ownerFullNameKana')
        return result
      }
    },
    isInvalidPostalCode: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'postalCode'
        )
        const result = !this.item.isValid
          ? false
          : !VALID_POSTALCODE_EMPTY_REGEX.test(value)
          ? '7桁の半角数字で入力して下さい　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? 'カルテ番号の上桁が同じで郵便番号が異なる行が存在します　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'postalCode')
        return result
      }
    },
    isInvalidFullAddress: function() {
      return function(value) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          'fullAddress'
        )
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? 'カルテ番号の上桁が同じで住所が異なる行が存在します　'
          : value.length > 50
          ? '50文字以内で入力して下さい　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, 'fullAddress')
        return result
      }
    },
    isInvalidTel: function() {
      return function(value, key) {
        const sameOwnerShowIdDeferentOwners = this.makeSameOwnerShowIdDeferentOwners(
          this.item.ownerShowId,
          this.ownerShowIdGroupObj,
          key
        )
        const tmp = key === 'homeTel' ? '自宅番号' : '携帯番号'
        const result = !this.item.isValid
          ? false
          : VALID_SURROGATE_PAIR_REGEX.test(value)
          ? '使用できない文字が含まれています　'
          : sameOwnerShowIdDeferentOwners.length > 0
          ? `カルテ番号の上桁が同じで${tmp}が異なる行が存在します` + '　'
          : value.length > 20
          ? '20文字以内で入力して下さい　'
          : false
        const invalid = result ? true : false
        this.checkInvalid(invalid, key)
        return result
      }
    }
  },

  methods: {
    makeSameOwnerShowIdDeferentOwners(ownerShowId, ownerShowIdGroupObj, key) {
      if (ownerShowId !== '') {
        const ownerShowIds = ownerShowIdGroupObj[ownerShowId]
        if (ownerShowIds !== undefined) {
          return ownerShowIds.filter(v => v[key] !== this.item[key])
        }
      } else {
        return []
      }
    },
    checkInvalid(invalid, key) {
      this.invalidFlg[key] = invalid
      this.$emit('check', {
        invalid: Object.values(this.invalidFlg).some(v => v === true),
        index: this.index
      })
      switch (key) {
        case 'medicalNum':
          this.errorMedicalNum = invalid
          break
        case 'patientName':
          this.errorPatientName = invalid
          break
        case 'speciesName':
          this.errorSpeciesName = invalid
          break
        case 'breed':
          this.errorBreed = invalid
          break
        case 'ownerFullName':
          this.errorOwnerFullName = invalid
          break
        case 'ownerFullNameKana':
          this.errorOwnerFullNameKana = invalid
          break
        case 'postalCode':
          this.errorPostalCode = invalid
          break
        case 'fullAddress':
          this.errorFullAddress = invalid
          break
        case 'homeTel':
          this.errorHomeTel = invalid
          break
        case 'mobileTel':
          this.errorMobileTel = invalid
      }
    },
    change(e) {
      const value = e.target.value === 'true' ? true : false
      this.$emit('input', {
        value,
        key: 'isValid',
        index: this.index,
        before: _.cloneDeep(this.item)
      })
    },
    input(e, key) {
      this.$emit('input', {
        value: e.target.value,
        key,
        index: this.index,
        before: _.cloneDeep(this.item)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.data-import-ainicom-receptor-form {
  display: flex;
  > .form {
    position: relative;
    box-sizing: border-box;
    width: 115px;
    margin-right: 5px;
    margin-bottom: 20px;
    > label {
      font-size: 15px;
    }
    > select {
      display: inline-block;
      box-sizing: border-box;
      width: 110px;
      height: 30px;
    }
    > input {
      box-sizing: border-box;
      width: 110px;
      height: 30px;
    }
    > .patient-show-id-info {
      font-size: 14px;
      white-space: nowrap;
    }
    > .owner-show-id-info {
      font-size: 14px;
      white-space: nowrap;
    }
    > .error {
      background: pink;
    }
    > .error-message {
      position: absolute;
      font-size: 12px;
      color: #{$tomato};
      background: #{$white};
      white-space: nowrap;
    }
    > .medicalNum {
      z-index: 9;
    }
    > .patient-name {
      z-index: 8;
    }
    > .species-name {
      z-index: 7;
    }
    > .breed {
      z-index: 6;
    }
    > .owner-full-name {
      z-index: 5;
    }
    > .owner-full-name-kana {
      z-index: 4;
    }
    > .postal-code {
      z-index: 3;
    }
    > .full-address {
      z-index: 2;
    }
    > .home-tel {
      z-index: 1;
    }
  }
}
</style>

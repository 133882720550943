import { hankanaToZenkana } from '@/utils/convert_string'
import {
  VALID_MEDICAL_RECORD_NUMBER_REGEX,
  VALID_ZERO_REGEX,
  VALID_POSTALCODE_EMPTY_REGEX,
  VALID_SURROGATE_PAIR_REGEX,
  VALID_FULL_NAME_REGEX,
  VALID_FULL_NAME_KANA_REGEX
} from '@/utils/define'
import _ from 'lodash'
import { parse } from 'csv-parse/lib/sync'

export default {
  methods: {
    mixinMakeCsvArrayAnicomReceptor(csvStr) {
      try {
        const rows = parse(csvStr, { trim: true })
        const csvArray = rows
          .map((row, index) => {
            if (row.length === 0) return
            const data = this.mixinReplaceElementsAnicomReceptor(row)
            if (
              (data[1] === 'true' || data[1] === 'false') &&
              data.length === 13
            ) {
              return {
                rowNo: parseInt(data[0]),
                isValid: data[1] === 'true' ? true : false,
                medicalNum: data[2],
                ownerShowId: data[2].substring(0, data[2].indexOf('-')),
                patientName: data[4],
                speciesName: data[5],
                breed: data[6],
                ownerFullName: data[7],
                ownerFullNameKana: data[8],
                postalCode: data[9],
                fullAddress: data[10],
                homeTel: data[11],
                mobileTel: data[12]
              }
            } else if (data.length === 11) {
              return {
                rowNo: index,
                isValid: true,
                medicalNum: data[0],
                ownerShowId: data[0].substring(0, data[0].indexOf('-')),
                patientName: data[1],
                speciesName: data[2],
                breed: data[3],
                ownerFullName: data[4],
                ownerFullNameKana: data[5],
                postalCode: data[6],
                fullAddress: data[7],
                homeTel: data[8],
                mobileTel: data[10]
              }
            } else {
              throw new Error('invalid row length')
            }
          })
          .filter(v => v !== undefined)
        this.csvArray = csvArray
        const csvArrayValidRowOnly = csvArray.filter(v => v.isValid === true)
        this.medicalNumGroupObj = _.cloneDeep(
          _.groupBy(csvArrayValidRowOnly, 'medicalNum')
        )
        this.ownerShowIdGroupObj = _.cloneDeep(
          _.groupBy(csvArrayValidRowOnly, 'ownerShowId')
        )
      } catch (err) {
        this.openErrorAnnounce('適切なデータとして読み込めませんでした')
      }
    },
    mixinReplaceElementsAnicomReceptor(data) {
      if (data[1] !== 'true' && data[1] !== 'false') {
        //文字化けした値があるとreplace処理でエラーになるので注意
        data[4] = _.trim(data[4]).replace(/[\x20\u3000]+/, '　')
        data[5] = hankanaToZenkana(_.trim(data[5]))
          .replace(/[\x20\u3000]+/, '　')
          .replace(/[・]/, '')
        data[6] = _.trim(data[6]).replace('-', '')
        data[10] = data[10].replace(/\s+/g, '')
      }
      return data
    },
    mixinMakeInvalidPagesAnicomReceptor() {
      const invalidRows = this.csvArray
        .map((row, i) => {
          if (!row.isValid) return
          const sameOwnerShowIdDeferentOwners = this.mixinCheckSameOwnerShowIdDifferentOwners(
            row
          )
          const ownerShowId = row.medicalNum.split('-')[0]
          const sameShowIdOwnerInDatabase = this.storeOwnerShowIdKeyObj[
            ownerShowId
          ]
          const sameShowIdPatientInDatabase = this.storePatientShowIdKeyObj[
            row.medicalNum
          ]
          const isInvalidMedicalNum =
            row.medicalNum.length === 0 ||
            !VALID_MEDICAL_RECORD_NUMBER_REGEX.test(row.medicalNum) ||
            VALID_ZERO_REGEX.test(row.medicalNum) ||
            this.medicalNumGroupObj[row.medicalNum].length >= 2 ||
            sameOwnerShowIdDeferentOwners.length > 0 ||
            row.medicalNum.length > 20 ||
            //↓上書きで、登録済み患者で、登録済み患者に紐づいた飼主のshowIdと入力した飼主showIdが一致しない場合
            (this.priority === 2 &&
              sameShowIdPatientInDatabase &&
              sameShowIdPatientInDatabase.ownerShowId !== ownerShowId) ||
            //↓既存優先で、登録済み患者で、新規飼主の場合。飼主だけの作成はできない仕様にしたのでバリエーションエラーにする
            (this.priority === 1 &&
              sameShowIdPatientInDatabase &&
              !sameShowIdOwnerInDatabase)

          if (
            isInvalidMedicalNum ||
            !row.patientName ||
            !row.patientName.match(/\S/g) ||
            VALID_SURROGATE_PAIR_REGEX.test(row.patientName) ||
            row.patientName.length > 30 ||
            !this.speciesNames.includes(row.speciesName) ||
            VALID_SURROGATE_PAIR_REGEX.test(row.breed) ||
            row.breed.length > 50 ||
            row.ownerFullName.length === 0 ||
            VALID_SURROGATE_PAIR_REGEX.test(row.ownerFullName) ||
            !VALID_FULL_NAME_REGEX.test(row.ownerFullName) ||
            row.ownerFullNameKana.length === 0 ||
            !VALID_FULL_NAME_KANA_REGEX.test(row.ownerFullNameKana) ||
            !VALID_POSTALCODE_EMPTY_REGEX.test(row.postalCode) ||
            VALID_SURROGATE_PAIR_REGEX.test(row.fullAddress) ||
            row.fullAddress.length > 50 ||
            VALID_SURROGATE_PAIR_REGEX.test(row.homeTel) ||
            row.homeTel.length > 20 ||
            VALID_SURROGATE_PAIR_REGEX.test(row.mobileTel) ||
            row.mobileTel.length > 20
          ) {
            return i + 1
          }
        })
        .filter(v => v !== undefined)
      const invalidPages = invalidRows.map(v => Math.ceil(v / this.rowNum))
      this.invalidPages = Array.from(new Set(invalidPages))
    },
    mixinCheckSameOwnerShowIdDifferentOwners(row) {
      const groupData = this.ownerShowIdGroupObj[row.ownerShowId] || []
      return groupData.filter(v => {
        return (
          v.ownerFullName !== row.ownerFullName ||
          v.ownerFullNameKana !== row.ownerFullNameKana ||
          v.postalCode !== row.postalCode ||
          v.fullAddress !== row.fullAddress ||
          v.homeTel !== row.homeTel ||
          v.mobileTel !== row.mobileTel
        )
      })
    },
    mixinInputCsvAnicomReceptor(obj) {
      this.$set(this.dispCsvArray[obj.index], obj.key, obj.value)
      this.$set(
        this.csvArray[obj.index + this.currentPageStartIndex],
        obj.key,
        obj.value
      )
      if (obj.key === 'isValid') {
        const copyObj = _.cloneDeep(obj)
        this.mixinReplaceMedicalGroupObjIsValid(obj)
        this.mixinReplaceOwnerShowIdGroupObjIsValid(copyObj)
      }
      if (obj.key === 'medicalNum') {
        const ownerShowId = obj.value.includes('-')
          ? obj.value.substring(0, obj.value.indexOf('-'))
          : ''
        this.$set(this.dispCsvArray[obj.index], 'ownerShowId', ownerShowId)
        this.$set(
          this.csvArray[obj.index + this.currentPageStartIndex],
          'ownerShowId',
          ownerShowId
        )
        obj.before.newOwnerShowId = ownerShowId
        const copyObj = _.cloneDeep(obj)
        this.mixinReplaceMedicalNumGroupObjMedicalNum(obj)
        this.mixinReplaceOwnerShowIdGroupObjMedicalNum(copyObj)
      }
      if (
        obj.key === 'ownerFullName' ||
        obj.key === 'ownerFullNameKana' ||
        obj.key === 'postalCode' ||
        obj.key === 'fullAddress' ||
        obj.key === 'homeTel' ||
        obj.key === 'mobileTel'
      ) {
        this.mixinReplaceOwnerShowIdGroupObjOwnerInfo(obj)
      }
    },
    mixinReplaceMedicalGroupObjIsValid(obj) {
      if (obj.value) {
        this.medicalNumGroupObj[obj.before.medicalNum] === undefined
          ? this.$set(this.medicalNumGroupObj, obj.before.medicalNum, [
              obj.before
            ])
          : this.medicalNumGroupObj[obj.before.medicalNum].push(obj.before)
      } else {
        if (this.medicalNumGroupObj[obj.before.medicalNum].length === 1) {
          this.$delete(this.medicalNumGroupObj, obj.before.medicalNum)
        } else {
          const newGroup = this.medicalNumGroupObj[
            obj.before.medicalNum
          ].filter(v => v.rowNo !== obj.before.rowNo)
          this.medicalNumGroupObj[obj.before.medicalNum].splice(
            0,
            this.medicalNumGroupObj[obj.before.medicalNum].length
          )
          this.medicalNumGroupObj[obj.before.medicalNum].push(...newGroup)
        }
      }
    },
    mixinReplaceOwnerShowIdGroupObjIsValid(obj) {
      const ownerShowId = obj.before.ownerShowId
      if (obj.value) {
        this.ownerShowIdGroupObj[ownerShowId] === undefined
          ? this.$set(this.ownerShowIdGroupObj, ownerShowId, [obj.before])
          : this.ownerShowIdGroupObj[ownerShowId].push(obj.before)
      } else {
        if (this.ownerShowIdGroupObj[ownerShowId].length === 1) {
          this.$delete(this.ownerShowIdGroupObj, ownerShowId)
        } else {
          const newGroup = this.ownerShowIdGroupObj[ownerShowId].filter(
            v => v.rowNo !== obj.before.rowNo
          )
          this.ownerShowIdGroupObj[ownerShowId].splice(
            0,
            this.ownerShowIdGroupObj[ownerShowId].length
          )
          this.ownerShowIdGroupObj[ownerShowId].push(...newGroup)
        }
      }
    },
    mixinReplaceMedicalNumGroupObjMedicalNum(obj) {
      if (this.medicalNumGroupObj[obj.before.medicalNum].length === 1) {
        this.$delete(this.medicalNumGroupObj, obj.before.medicalNum)
      } else {
        const newGroup = this.medicalNumGroupObj[obj.before.medicalNum].filter(
          v => v.rowNo !== obj.before.rowNo
        )
        this.medicalNumGroupObj[obj.before.medicalNum].splice(
          0,
          this.medicalNumGroupObj[obj.before.medicalNum].length
        )
        this.medicalNumGroupObj[obj.before.medicalNum].push(...newGroup)
      }
      obj.before.medicalNum = obj.value
      this.medicalNumGroupObj[obj.value] === undefined
        ? this.$set(this.medicalNumGroupObj, obj.value, [obj.before])
        : this.medicalNumGroupObj[obj.value].push(obj.before)
    },
    mixinReplaceOwnerShowIdGroupObjMedicalNum(obj) {
      const oldOwnerShowId = obj.before.ownerShowId
      const newOwnerShowId = obj.before.newOwnerShowId
      if (oldOwnerShowId) {
        if (this.ownerShowIdGroupObj[oldOwnerShowId].length === 1) {
          this.$delete(this.ownerShowIdGroupObj, oldOwnerShowId)
        } else {
          const newGroup = this.ownerShowIdGroupObj[oldOwnerShowId].filter(
            v => v.rowNo !== obj.before.rowNo
          )
          this.ownerShowIdGroupObj[oldOwnerShowId].splice(
            0,
            this.ownerShowIdGroupObj[oldOwnerShowId].length
          )
          this.ownerShowIdGroupObj[oldOwnerShowId].push(...newGroup)
        }
      }
      if (newOwnerShowId) {
        obj.before.medicalNum = obj.value
        this.ownerShowIdGroupObj[newOwnerShowId] === undefined
          ? this.$set(this.ownerShowIdGroupObj, newOwnerShowId, [obj.before])
          : this.ownerShowIdGroupObj[newOwnerShowId].push(obj.before)
      }
    },
    mixinReplaceOwnerShowIdGroupObjOwnerInfo(obj) {
      const ownerShowId = obj.before.ownerShowId
      const index = this.ownerShowIdGroupObj[ownerShowId].findIndex(
        v => v.rowNo === obj.before.rowNo
      )
      obj.before[obj.key] = obj.value
      this.$set(this.ownerShowIdGroupObj[ownerShowId], index, obj.before)
    }
  }
}

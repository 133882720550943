<template>
  <div class="data-import">
    <base-loading :waitFlg="waitFlg" />
    <div class="section input-area">
      <radio-button-form
        class="radio-button-form"
        v-bind="radioButtonData"
        :value="selectCsvType"
        @input="inputCsvType"
        :titleStyles="{ width: '134px', textAlign: 'left' }"
        :contentStyles="{ width: '580px' }"
      />
      <div class="button-wrapper">
        <input
          v-if="selectCsvType !== ''"
          type="file"
          @change="uploadCsv"
          data-test="input-file"
          data-e2e="input-file"
        />
        <base-button-medium-orange
          v-if="selectCsvType === 'petorelu-plus' && csvArray.length === 0"
          :styles="{ height: '33px' }"
          @click="downloadSample"
          >書式サンプルダウンロード</base-button-medium-orange
        >
        <base-button-small-orange
          v-if="csvArray.length !== 0"
          @click="downloadCsv"
          >CSVダウンロード</base-button-small-orange
        >
      </div>
      <div class="pagination" v-if="csvArray.length !== 0">
        <div
          v-for="page in totalPages"
          :key="page + 'page'"
          :class="{
            page: true,
            'invalid-page': invalidPages.includes(page),
            'current-page': currentPage === page
          }"
          @click="movePage(page)"
          data-test="page"
        >
          {{ ('000' + page).slice(-3) }}
        </div>
      </div>
      <div v-if="invalid" class="error-message" data-test="error-message">
        エラー箇所をメッセージに従って修正して下さい。
      </div>
      <div
        v-else-if="!invalid && invalidPages.length !== 0"
        class="error-message"
        data-test="error-message"
      >
        赤文字のページに移動し、エラー箇所を修正して下さい。
      </div>
      <div v-else class="message-block" data-test="error-message"></div>
      <select-box-form
        v-if="csvArray.length !== 0"
        :selectData="selectBoxData.selectData"
        :note="note"
        :value="priority"
        @input="changePriority"
        >優先度</select-box-form
      >
      <div class="table">
        <div class="import-types">
          <div class="input-table">
            <div v-for="(item, index) in dispCsvArray" :key="item.id">
              <data-import-petorelu-plus-form
                v-if="selectCsvType === 'petorelu-plus'"
                :item="item"
                :index="index"
                :ownerShowIdGroupObj="ownerShowIdGroupObj"
                :patientShowIdGroupObj="patientShowIdGroupObj"
                :mcCodeGroupObj="mcCodeGroupObj"
                :emailGroupObj="emailGroupObj"
                :storePatientShowIdKeyObj="storePatientShowIdKeyObj"
                :storePatientMcCodeKeyObj="mixinStorePatientMcCodeKeyObj"
                :storeOwnerShowIdKeyObj="storeOwnerShowIdKeyObj"
                :storeOwnerEmailKeyObj="mixinStoreOwnerEmailKeyObj"
                :patientSexNamesZero="mixinPatientSexNamesZero"
                :speciesNames="speciesNames"
                :prefectureNamesZero="mixinPrefectureNamesZero"
                :priority="priority"
                @check="checkInvalid"
                @input="inputCsvDelay"
              />
              <data-import-anicom-receptor-form
                v-if="selectCsvType === 'anicom-receptor'"
                :item="item"
                :index="index"
                :medicalNumGroupObj="medicalNumGroupObj"
                :ownerShowIdGroupObj="ownerShowIdGroupObj"
                :storePatientShowIdKeyObj="storePatientShowIdKeyObj"
                :storeOwnerShowIdKeyObj="storeOwnerShowIdKeyObj"
                :priority="priority"
                @check="checkInvalid"
                @input="inputCsvDelay"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section button-area">
      <base-button-medium-orange
        class="button"
        v-if="adminFlg === 1"
        :disabled="
          invalidPages.length > 0 || invalid || waitFlg || csvArray.length === 0
        "
        @click="addData"
        >登録</base-button-medium-orange
      >
    </div>
    <announce-popup
      v-if="announce.showFlg"
      :title="announce.title"
      :buttons="announce.buttons"
      :type="announce.type"
      :oneButtonColor="announce.buttonColor"
      @close="announceButtonAction"
      >{{ announce.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import RadioButtonForm from '@/components/parts/molecules/RadioButtonForm.vue'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import DataImportPetoreluPlusForm from '@/components/parts/organisms/DataImportPetoreluPlusForm'
import DataImportAnicomReceptorForm from '@/components/parts/organisms/DataImportAnicomReceptorForm'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import AnicomReceptor from '@/components/mixins/data_import/AnicomReceptor'
import PetoreluPlusFormat from '@/components/mixins/data_import/PetoreluPlusFormat'
import { mapGetters } from 'vuex'
import { getAxiosObject } from '@/utils/library'
import _ from 'lodash'
import Encoding from 'encoding-japanese'
import { stringify } from 'csv-stringify/lib/sync'

export default {
  name: 'DataImport',

  components: {
    BaseLoading,
    RadioButtonForm,
    BaseButtonSmallOrange,
    SelectBoxForm,
    DataImportPetoreluPlusForm,
    DataImportAnicomReceptorForm,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [CheckInputDifference, AnicomReceptor, PetoreluPlusFormat],

  data() {
    return {
      radioButtonData: {
        title: '取込csvタイプ',
        radioButtonData: [
          {
            id: 1,
            eachValue: 'petorelu-plus',
            labelName: 'ペトレルプラス書式'
          },
          {
            id: 2,
            eachValue: 'anicom-receptor',
            labelName: 'アニコムレセプター（インストール型）書式'
          }
        ]
      },
      selectCsvType: '',
      selectBoxData: {
        selectData: [
          { id: 1, name: '既存優先' },
          { id: 2, name: '上書き' }
        ],
        note:
          'カルテ番号と登録済み飼主ID・患者IDが一致する場合、どちらのデータを優先するかを選択します。\n例：カルテ番号が「0001-01」の場合\n飼主IDが「0001」\n患者IDが「0001-01」となります。\n「既存優先」の場合、登録済み飼主・患者のデータを優先します。\n「上書き」の場合、取込むデータを優先して飼主・患者データを上書きします。'
      },
      priority: 1,
      waitFlg: false,
      announce: {
        showFlg: false,
        title: '',
        buttons: [],
        message: '',
        type: '',
        buttonColor: ''
      },
      csvArray: [],
      dispCsvArray: [],
      currentPageStartIndex: 0,
      rowNum: 50,
      currentPage: 1,
      totalPages: [1],
      invalidPages: [],
      invalids: [],
      invalid: false,
      medicalNumGroupObj: {},
      ownerShowIdGroupObj: {},
      patientShowIdGroupObj: {},
      mcCodeGroupObj: {},
      emailGroupObj: {},
      resultCsv: [],
      timeoutId: null,
      delay: 300,
      canLeave: true
    }
  },

  computed: {
    ...mapGetters({
      adminFlg: 'auth/adminFlg'
    }),
    speciesNames() {
      return this.$store.getters['species/getData'].map(v => v.name)
    },
    note() {
      return this.selectCsvType === 'anicom-receptor'
        ? 'カルテ番号と登録済み飼主ID・患者IDが一致する場合、どちらのデータを優先するかを選択します。\n例：カルテ番号が「0001-01」の場合\n飼主IDが「0001」\n患者IDが「0001-01」となります。\n「既存優先」の場合、登録済み飼主・患者のデータを優先します。\n「上書き」の場合、取込むデータを優先して飼主・患者データを上書きします。'
        : '飼主IDと登録済み飼主ID、または患者IDと登録済み患者IDが一致する場合、どちらのデータを優先するかを選択します。\n「既存優先」の場合、登録済み飼主・患者のデータを優先します。\n「上書き」の場合、取込むデータを優先して飼主・患者データを上書きします。'
    },
    storePatientShowIdKeyObj() {
      if (
        this.selectCsvType === 'petorelu-plus' ||
        this.selectCsvType === 'anicom-receptor'
      ) {
        const storePatientsIncludeOwnerShowId = this.$store.getters[
          'patients/getData'
        ].flatMap(patient => {
          if (patient.showId.length > 0) {
            const ownerShowId = this.$store.getters['owners/getDataById'](
              patient.ownerId
            ).showId // 空文字の場合は？
            return { ...patient, ownerShowId }
          } else {
            //plus_netで空文字の場合もあるので取り除く
            return []
          }
        })
        return _.keyBy(storePatientsIncludeOwnerShowId, 'showId')
      } else {
        return null
      }
    },
    storeOwnerShowIdKeyObj() {
      return this.selectCsvType === 'petorelu-plus' ||
        this.selectCsvType === 'anicom-receptor'
        ? _.keyBy(
            this.$store.getters['owners/getData'].filter(
              v => v.showId.length > 0 //plus_netで空文字の場合もあるので取り除く
            ),
            'showId'
          )
        : null
    }
  },

  created() {
    this.mixinInitialData = this.selectCsvType
    this.mixinInputData = this.selectCsvType
  },

  beforeRouteLeave(to, from, next) {
    if (this.canLeave) {
      next()
    } else {
      next(false)
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    inputCsvType(value) {
      this.selectCsvType = value
      this.mixinInputData = value
    },
    uploadCsv(e) {
      this.waitFlg = true
      setTimeout(async () => {
        const res = await this.readCsv(e)
        if (res[0]) {
          switch (this.selectCsvType) {
            case 'petorelu-plus':
              this.mixinMakeCsvArrayPetoreluPlusFormat(res[1])
              this.changeDispCsvArray()
              this.makeTotalPages()
              this.mixinMakeInvalidPagesPetoreluPlusFormat()
              break
            case 'anicom-receptor':
              this.mixinMakeCsvArrayAnicomReceptor(res[1])
              this.changeDispCsvArray()
              this.makeTotalPages()
              this.mixinMakeInvalidPagesAnicomReceptor()
          }
          this.disabledRadioButtonData()
        } else {
          this.openErrorAnnounce('ファイルの読込に失敗しました')
        }
        this.waitFlg = false
      }, 100)
    },
    readCsv(e) {
      return new Promise((resolve, reject) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        file ? reader.readAsArrayBuffer(file) : (this.waitFlg = false)
        reader.onload = () => {
          const binary = new Uint8Array(reader.result)
          let csvStr
          switch (Encoding.detect(binary)) {
            case 'UTF8':
              csvStr = new TextDecoder().decode(binary)
              break
            case 'SJIS': {
              const utf8Array = Encoding.convert(binary, {
                to: 'UNICODE',
                from: 'SJIS'
              })
              csvStr = Encoding.codeToString(utf8Array)
              break
            }
          }
          resolve([true, csvStr])
        }
        reader.onerror = () => reject([false])
      })
    },
    changeDispCsvArray() {
      this.dispCsvArray = this.csvArray.slice(
        this.currentPageStartIndex,
        this.currentPageStartIndex + this.rowNum
      )
    },
    disabledRadioButtonData() {
      this.radioButtonData = {
        title: '取込csvタイプ',
        radioButtonData: [
          {
            id: 1,
            eachValue: 'petorelu-plus',
            labelName: 'ペトレルプラス書式',
            disabled: this.selectCsvType !== 'petorelu-plus'
          },
          {
            id: 2,
            eachValue: 'anicom-receptor',
            labelName: 'アニコムレセプター（インストール型）書式',
            disabled: this.selectCsvType !== 'anicom-receptor'
          }
        ]
      }
    },
    makeTotalPages() {
      if (this.csvArray.length > this.rowNum) {
        const totalPageNumber = Math.ceil(this.csvArray.length / this.rowNum)
        this.totalPages = [...Array(totalPageNumber).keys()].map(i => ++i)
      }
    },
    openErrorAnnounce(message) {
      this.announce = {
        showFlg: true,
        title: '失敗',
        buttons: ['閉じる'],
        message,
        type: 'alert',
        buttonColor: 'white'
      }
    },
    movePage(page) {
      this.waitFlg = true
      this.currentPageStartIndex = this.rowNum * (page - 1)
      this.currentPage = page
      this.changeDispCsvArray()
      this.waitFlg = false
    },
    checkInvalid(obj) {
      this.invalids[obj.index] = obj.invalid
      this.invalid = this.invalids
        .map((v, i) => {
          if (i < this.dispCsvArray.length) {
            return !this.dispCsvArray[i].isValid ? false : v
          }
        })
        .some(v => v === true)
      if (this.invalid) {
        const invalidCurrentPage = this.invalidPages.find(
          page => page === this.currentPage
        )
        if (!invalidCurrentPage) {
          this.invalidPages.push(this.currentPage)
        }
      } else {
        const invalidCurrentPageIndex = this.invalidPages.findIndex(
          page => page === this.currentPage
        )
        if (invalidCurrentPageIndex !== -1) {
          this.invalidPages.splice(invalidCurrentPageIndex, 1)
        }
      }
    },
    changePriority(priority) {
      this.priority = parseInt(priority)
      if (this.selectCsvType === 'petorelu-plus') {
        this.mixinMakeInvalidPagesPetoreluPlusFormat()
      } else if (this.selectCsvType === 'anicom-receptor') {
        this.mixinMakeInvalidPagesAnicomReceptor()
      }
    },
    inputCsvDelay(obj) {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.inputCsv(obj)
      }, this.delay)
    },
    inputCsv(obj) {
      if (this.selectCsvType === 'anicom-receptor') {
        this.mixinInputCsvAnicomReceptor(obj)
      } else if (this.selectCsvType === 'petorelu-plus') {
        this.mixinInputCsvPetoreluPlus(obj)
      }
    },
    async addData() {
      try {
        this.waitFlg = true
        const priority = this.priority === 1 ? 'lowPriority' : 'overWrite'
        const axiosObject = getAxiosObject()
        const res = await axiosObject.post(
          `/data-import-${this.selectCsvType}`,
          { csv: this.csvArray, priority }
        )
        this.canLeave = false
        this.resultCsv = res.data.csv
        this.announce = {
          showFlg: true,
          title: '完了',
          buttons: ['ログイン画面に戻る'],
          message: `データ取込が完了しました。\n取込結果を出力しログイン画面に遷移します。`,
          type: 'success',
          buttonColor: 'orange'
        }
      } catch (err) {
        this.openErrorAnnounce('データ取込に失敗しました。')
      } finally {
        this.waitFlg = false
      }
    },
    announceButtonAction() {
      switch (this.announce.buttons[0]) {
        case '閉じる':
          this.announce.showFlg = false
          break
        case 'ログイン画面に戻る':
          this.canLeave = true
          this.downloadCsv()
          this.$router.push({ name: 'login' })
      }
    },
    makeCsvStr(csv) {
      return stringify(csv, {
        cast: {
          boolean: function(value) {
            return value.toString()
          }
        }
      })
    },
    downloadCsv() {
      let csvStr, fileName
      switch (this.selectCsvType) {
        case 'petorelu-plus': {
          if (this.resultCsv.length > 0) {
            csvStr = this.makeCsvStr(this.resultCsv)
            fileName = 'result_petorelu_plus.csv'
          } else {
            csvStr = this.makeCsvStr(this.csvArray)
            fileName = 'save_petorelu_plus.csv'
          }
          break
        }
        case 'anicom-receptor': {
          if (this.resultCsv.length > 0) {
            csvStr = this.makeCsvStr(this.resultCsv)
            fileName = 'result_anicom_receptor.csv'
          } else {
            csvStr = this.makeCsvStr(this.csvArray)
            fileName = 'save_anicom_receptor.csv'
          }
        }
      }
      this.downloadFunc(csvStr, fileName)
      this.announce.showFlg = false
      this.$store.dispatch('petorelu/okLeave')
    },
    downloadFunc(csvStr, fileName) {
      window.alert(csvStr)
      const bom = new Uint8Array([0xef, 0xbb, 0xbf])
      const blob = new Blob([bom, csvStr], { type: 'text/csv' })
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName)
      } else {
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = fileName
        anchor.click()
      }
    },
    downloadSample() {
      const anchor = document.createElement('a')
      anchor.href = 'petorelu-plus-sample.csv'
      anchor.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.data-import {
  text-align: left;
  margin-top: 38px;
  > .section {
    margin-top: 25px;
    > .radio-button-form {
      margin-bottom: 10px;
    }
    > .button-wrapper {
      display: flex;
      > input {
        margin: 5px 10px 10px 0;
      }
    }
    > .pagination {
      display: flex;
      max-width: 1000px;
      flex-wrap: wrap;
      > .page {
        padding-right: 10px;
        &:hover {
          color: #{$blue};
          cursor: pointer;
        }
      }
      > .invalid-page {
        color: #{$tomato};
      }
      > .current-page {
        font-weight: bold;
        color: #{$blue};
        pointer-events: none;
      }
    }
    > .error-message {
      height: 20px;
      color: #{$tomato};
    }
    > .message-block {
      height: 20px;
    }
    > .select-box-form {
      margin-top: 10px;
      width: 200px;
    }
    > .table {
      display: flex;
      > .import-types {
        > .header {
          display: flex;
          box-sizing: border-box;
          height: 30px;
          margin-bottom: 5px;
          div {
            box-sizing: border-box;
            width: 130px;
            margin-right: 5px;
          }
        }
        > .input-table {
          > .csv-area {
            > .rows {
              margin-bottom: 5px;
              display: flex;
              > .elements {
                display: block;
                margin-right: 5px;
                > .validation-provider {
                  > input {
                    width: 130px;
                    box-sizing: border-box;
                    height: 30px;
                  }
                  > .error {
                    background: pink;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.button-area {
      display: flex;
      justify-content: center;
      margin: 60px 0;
    }
  }
}
</style>
